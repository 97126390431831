import React from "react";

import { Avatar, CardHeader } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import Badge from "@material-ui/core/Badge";

const TicketInfo = ({ contact, ticket, onClick }) => {
	const tag = ticket.tagTicket ? (
		<Badge
			style={{backgroundColor: ticket.tagTicket.color, color: '#000',
				fontSize:11, borderRadius:10, padding:'3px 5px', width:'auto', marginLeft:7, marginBottom:5
			}}
		>
			<span style={{padding:0,margin:0}}>{ticket.tagTicket.name}</span>
		</Badge>
	) : '';

	return (
		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer" }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
			title={<>{contact.name} #{ticket.id} {tag}</>}
			subheader={
				ticket.user &&
				`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
			}
		/>
	);
};

export default TicketInfo;
