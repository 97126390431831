import React, {useState, useEffect, useRef, useContext} from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Muito pequeno!")
        .max(50, "Muito grande!")
        .required("Campo obrigatório!"),

});

const KanbanModal = ({ open, onClose, initialValues, onSave, position, edit }) => {
    const classes = useStyles();
    const isMounted = useRef(true);

    const { user } = useContext(AuthContext);


    const [contact, setContact] = useState();

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, [edit]);

    useEffect(() => {
        setContact({
            name: edit.name ?? "",
            user_id: user.id,
            position: position,
            company_id: user.company_id
        })

    }, [edit]);



    const handleClose = () => {
        onClose();
        setContact({  name: "",
            user_id: user.id,
            position: position,
            company_id: user.company_id});
    };

    const handleSave = async values => {
        if (edit && edit.id){
            values.id = edit.id
        }else{
            values.id = null
        }
        values.profile = user.profile
        values.lastName = edit.name
        const { data } = await api.post("/kanbans", values);
        window.location.reload();
        onClose()
        onSave()
        handleClose();
    };

    return (
        <div className={classes.root}>

            <Dialog open={open} onClose={handleClose} width="lg" scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {edit && edit.id ? 'Editar' : ' Adicionar novo'}
                </DialogTitle>
                <Formik
                    initialValues={contact}
                    enableReinitialize={true}
                    validationSchema={ContactSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSave(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, touched, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <Typography variant="subtitle1" gutterBottom>
                                    Nome da coluna
                                </Typography>
                                <Field
                                    as={TextField}
                                    label={''}
                                    name="name"
                                    autoFocus
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textField}
                                />

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("contactModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    Adicionar
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default KanbanModal;
