import React, {useState, useEffect, useRef, useContext} from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import {FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select} from "@material-ui/core";
import { Colorize } from "@material-ui/icons";
import {AuthContext} from "../../context/Auth/AuthContext";
import useWhatsApps from "../../hooks/useWhatsApps";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}));

const QueueSchema = Yup.object().shape({

    name: Yup.string()
        .min(2, "Muito pequeno!")
        .max(50, "Muito grande!")
        .required("Obrigatório"),
    color: Yup.string().min(3, "Muito pequeno!").max(9, "Muito grande!").required('Obrigatório'),
});

const TagTicket = ({ open, onClose,  whatsappId}) => {

    const [tagsFull, setTagsfull] = useState([]);
    const [initial, setInitial] = useState({
        name: "",
        color: "#007bff",
    });
    const classes = useStyles();
    const { user } = useContext(AuthContext);


    useEffect(() => {
        (async () => {
            if (!whatsappId) return;
            try {
                const { data } = await api.get(`/tagTicket/${whatsappId}/${user.company_id}`);

                setTagsfull(data)
            } catch (err) {
                //toastError(err);
            }
        })();

    }, [whatsappId, open]);

    const handleClose = () => {
        onClose();
    };

    const handleSaveQueue = async values => {
        setInitial({
            name: "",
            color: "#007bff",
        })

        values.company_id = user.company_id;
        values.whatsapp_id = whatsappId;
        try {
            const {data} = await api.post("/tagTicket", values);
            setTagsfull([...tagsFull, data])
            toast.success("Etiqueta adicionada com sucesso!");
            document.getElementById('name').value = '';

        } catch (err) {
            toastError(err);
        }
    };

    const deleteTag = async values => {
        try {
            await api.delete("/tagTicket/"+values);

            const res = tagsFull.filter(t =>{
                return t.id !== values
            })

            setTagsfull(res)
            toast.success("Etiqueta deletada com sucesso!");
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                style={{width:'80%'}}
                fullScreen={true}
                maxWidth="lg"
                scroll="paper"
            >
                <DialogTitle>
                  Etiquetas
                </DialogTitle>
                <Formik
                    initialValues={initial}
                    enableReinitialize={true}
                    validationSchema={QueueSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveQueue(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, values }) => (
                        <Form>
                            <DialogContent dividers>
                               <div style={{display:'flex', flexDirection:'row', width: 399, alignItems:'center'}}>
                                   <Field
                                       as={TextField}
                                       label={'Nome da etiqueta'}
                                       autoFocus
                                       name="name"
                                       error={touched.name && Boolean(errors.name)}
                                       id={'name'}
                                       variant="outlined"
                                       margin="dense"
                                       className={classes.textField}
                                   />
                                   <div style={{marginTop:-20}}>
                                       <p style={{marginBottom:0}}>Cor da etiqueta</p>
                                       <Field
                                           type={'color'}
                                           label={'Cor da etiqueta'}
                                           name="color"
                                           error={touched.color && Boolean(errors.color)}
                                           helperText={touched.color && errors.color}
                                           autoFocus
                                           margin="dense"
                                           className={classes.textField}
                                       />
                                   </div>

                               </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("queueModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                   Adicionar
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                            <div style={{padding:20}}>
                                {tagsFull.length > 0 && (
                                    <div style={{display:'flex',flex:1, flexWrap:'wrap', width:'100%'}}>
                                        {tagsFull.map((t, indice) => (
                                            <span id={t.id}  style={{
                                                margin:3}}>
                                          <span style={{padding:7,
                                              borderRadius:5,
                                              width: 'auto',
                                              height:30,
                                              backgroundColor: t.color,
                                              color:'#fff'
                                          }}>{t.name}

                                    </span>
                                        <HighlightOffIcon
                                            onClick={() => deleteTag(t.id)}
                                            style={{
                                                marginLeft:-10,
                                                marginBottom:7,
                                                cursor:'pointer',
                                                backgroundColor:'#fff',
                                                borderRadius:50,
                                                color:'black !important'}}
                                        />
                                    </span>

                                        ))}
                                    </div>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default TagTicket;
