import React, {useState, useEffect, useRef, useContext} from "react";
import {  Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import {Grid, Paper} from "@material-ui/core";
import api from "../../services/api";
import {AuthContext} from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));



const KanbanColors = ({open, onClose, colors, setColorNew }) => {
    const classes = useStyles();
    const [color, setColor] = useState('#312f52');
    const [addColor, setAddcolor] = useState('');
    const isMounted = useRef(true);

    const {user} = useContext(AuthContext);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    const handleClose = () => {
        onClose();
    };


    const handleSave = async () => {

        const values = {
            color: color,
            user_id: user.id,
            company_id: user.company_id,
        }

        const { data } = await api.post("/kanbansColor", values);

        colors(data)
        setColorNew(color)
        onClose()

        toast.success('Cor adicionada com sucesso!')
        //window.location.reload();
    };

    return (
        <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth={'xs'} >
            <Form>
                <DialogContent dividers>

                    <Typography variant="subtitle1" gutterBottom>
                        Adicionar nova cor
                    </Typography>
                    <Field
                        type={'color'}
                        onChange={(e) => setColor(e.target.value)}
                        value={color}
                        label={''}
                        name="bgcolor"
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="default"
                        variant="outlined"
                    >
                       Cancelar
                    </Button>
                    <Button
                        onClick={() => handleSave()}
                        type="button"
                        color="primary"
                        variant="contained"
                        className={classes.btnWrapper}
                    >
                        Adicionar
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export default KanbanColors;
