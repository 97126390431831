import React from "react";

import { Formik, Form, Field } from "formik";


import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {ArrowForward} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));



const VariaveisModal = ({ open, onClose }) => {
	const classes = useStyles();

	const handleClose = () => {
		onClose();

	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
					Variáveis e Formatação
				</DialogTitle>

							<DialogContent dividers>
								<div className={classes.multFieldLine} style={{width: '100%',flexDirection:'column', display:'flex', }}>
									<h2 style={{margin:0}}>Variáveis disponíveis</h2>
									<p>Você poderá utilizar as variáveis abaixo para inserir informações dinamicamente às mensagens.</p>
									<div style={{display:'flex', alignItems:'center'}}>
										<h3><span style={{color:'tomato'}}>%NAME%</span>
										</h3>
										<ArrowForward style={{margin:10}}/>
										<h3> Insere o nome do contato.</h3>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<h3><span style={{color:'tomato'}}>%LINK%</span>
										</h3>
										<ArrowForward style={{margin:10}}/>
										<h3>  Insere o link para o seu canal do WhatsApp.</h3>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<h3><span style={{color:'tomato'}}>%OPERATOR%</span>
										</h3>
										<ArrowForward style={{margin:10}}/>
										<h3>  Insere o nome do atendente.</h3>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<h3><span style={{color:'tomato'}}>%DEPARTMENT%</span>
										</h3>
										<ArrowForward style={{margin:10}}/>
										<h3>  Insere o nome do departamento.</h3>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<h3><span style={{color:'tomato'}}>%PROTOCOL%</span>
										</h3>
										<ArrowForward style={{margin:10}}/>
										<h3>  Insere o número do protocolo.</h3>
									</div>
									<hr/>
									<h2 style={{margin:0}}>Formatação de Texto
									</h2>
									<p>
										Para formatar o texto das mensagens, você deve adicionar os caracteres abaixo, antes e depois de cada palavra que deseja modificar. Por exemplo, digite: *Texto*  Resultado: <b>Texto</b>
									</p>

									<div style={{display:'flex', alignItems:'center'}}>
										<h3><span style={{color:'tomato'}}>*</span> (asterisco)
										</h3>
										<ArrowForward style={{margin:10}}/>
										<h3>  Adiciona <b>negrito</b> ao texto.</h3>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<h3><span style={{color:'tomato'}}>_</span> (underline)
										</h3>
										<ArrowForward style={{margin:10}}/>
										<h3>  Adiciona <i>itálico</i> ao texto.</h3>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<h3><span style={{color:'tomato'}}>˜</span> (acento ˜)
										</h3>
										<ArrowForward style={{margin:10}}/>
										<h3>  Adiciona <s>riscado</s> ao texto.</h3>
									</div>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									variant="outlined"
								>
									Fechar
								</Button>

							</DialogActions>
			</Dialog>
		</div>
	);
};

export default React.memo(VariaveisModal);
