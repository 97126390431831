import React, {useState, useEffect, useContext} from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Info from '@material-ui/icons/Info';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import ArrowForward from '@material-ui/icons/ArrowForward';
import CropOriginal from '@material-ui/icons/CropOriginal';
import DeleteIcon from '@material-ui/icons/Delete';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {Picker} from "emoji-mart";
import MoodIcon from "@material-ui/icons/Mood";
import IconButton from "@material-ui/core/IconButton";
import {Divider, Typography} from "@material-ui/core";
import {AuthContext} from "../../context/Auth/AuthContext";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));


const ChatBotModal = ({ open, onClose, whatsAppId }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const { user, tokenWhats } = useContext(AuthContext);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseP = () => {
		setAnchorEl(null);
	};

	const initialState = {
		name: "",
		greetingMessage: "",
		farewellMessage: "",
		isDefault: false,
	};
	const [whatsApp, setWhatsApp] = useState(initialState);
	const [msg_chatbot, setMsgchatbot] = useState('');
	const [chatbot_active, setChatbotactive] = useState('');
	const [img_chatbot, setImgchatbot] = useState('');
	const [imgPreview, setImgPreview] = useState('');
	const [imgPreview1, setImgPreview1] = useState('');

	const [id, setId] = useState('');
	const [msg, setMsg] = useState('');
	const [img, setImg] = useState('');
	const [option, setOption] = useState('')
	const [name, setName] = useState('')
	const [keys_name, setKeysname] = useState('')
	const [tagSelect, setTagselect] = useState('');
	const [queueSelect, setQueueselect] = useState('');
	const [userSelect, setUserselect] = useState('');
	const [newsletter, setNewsletter] = useState(0);


	const [showEmoji, setShowEmoji] = useState(false);
	const [showEmoji2, setShowEmoji2] = useState(false);
	const [showEmoji3, setShowEmoji3] = useState(false);

	const [queue, setQueue] = useState([]);
	const [tagsFull, setTagsfull] = useState([]);
	const [usersfull, setUsersfull] = useState([]);
	const [chatsbots, setChatbots] = useState([]);

	const [openP, setOpenp] = useState(false);
	const [parametros, setParametros] = useState({
		attendant: 0,
		contact:0,
		ticket:0,
	});
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);

	useEffect(() => {
		const fetchSession = async () => {

			setId('');
			setImgPreview1('');
			setMsg('');
			setImg('');
			setOption('');
			setName('');
			setKeysname('');
			setTagselect(0);
			setQueueselect('');
			setUserselect('');
			setNewsletter('');

			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsappId/${whatsAppId}`);
				setWhatsApp(data);
				setChatbotactive(data.chatbot_active ?? 0)
				setMsgchatbot(data.msg_chatbot)
				setImgchatbot(data.img_chatbot ?? '')
				setImgPreview(data.img_chatbot ?? '')

			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`/tagTicket/${whatsAppId}/${user.company_id}`);

				setTagsfull(data)

			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`queueWhatsUser/${user.company_id}/${whatsAppId}`);
				setQueue(data)
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`/chatbot/${whatsAppId}`);
				setChatbots(data)
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	const handleSaveChatbot = async () => {
		if (!option || !queueSelect || !name){
			return 	toast.error("Falta campos para preencher!");
		}
		try {

			const formData = new FormData();
			formData.append("id", id);
			formData.append("msg", msg);
			formData.append("img", img.name ? '' : img);
			formData.append("option", option);
			formData.append("name", name);
			formData.append("keys_name", keys_name);
			formData.append("whatsapp_id", whatsAppId);
			formData.append("company_id", user.company_id);
			formData.append("tagticket_id", tagSelect ?? 0);
			formData.append("queue_id", queueSelect);
			formData.append("user_id", userSelect);
			formData.append("newsletter", newsletter);
			formData.append("imgs", img.name ? img : [] );

			await api.post(`/chatbot/`, formData);

			setTimeout(async () => {
				const { data } = await api.get(`/chatbot/${whatsAppId}`);
				setChatbots(data)
			}, 1000)

			newChatBot();

			toast.success("Chat-bot salvo com sucesso!");
		} catch (err) {
			toastError(err);
		}
	};

	function newChatBot(){
		setId('');
		setImgPreview1('');
		setMsg('');
		setImg('');
		setOption('');
		setName('');
		setKeysname('');
		setTagselect(0);
		setQueueselect('');
		setUserselect('');
		setNewsletter('');
	}

	const handleSaveWhatsApp = async () => {
		try {
			const formData = new FormData();
			formData.append("chatbot_active", chatbot_active ? 1 : 0);
			formData.append("msg_chatbot", msg_chatbot);
			formData.append("img_chatbot", img_chatbot.name ? '' : img_chatbot);
			formData.append("imgs", img_chatbot.name ? img_chatbot : [] );

			await api.post(`/whatsappChatbot/${whatsAppId}`, formData);

			toast.success("Chat-bot salvo com sucesso!");
		} catch (err) {
			toastError(err);
		}
	};

	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
	};

	const handleQueueSelect = (value) => {
		setUserselect('');
		setQueueselect(value)
		const res = queue.filter(q => {
			return value === q.id
		})
		setUsersfull(res[0].users)
	};

	const handleAddEmoji = (e, type) => {
		let emoji = e.native;
		if (type === 'msg_chatbot'){
			setMsgchatbot((prevState) => prevState + emoji);
		}if (type === 'msg'){
			setMsg((prevState) => prevState + emoji);
		}if (type === 'name'){
			setName((prevState) => prevState + emoji);
		}
	};

	const handleChangeMedias = (e) => {
		if (!e.target.files) {
			return;
		}

		const selectedMedias = Array.from(e.target.files);
		setImgchatbot(selectedMedias[0]);

		var file = selectedMedias[0];

		const objectUrl = URL.createObjectURL(file)
		setImgPreview(objectUrl)
	};

	const handleChangeMedias1 = (e) => {
		if (!e.target.files) {
			return;
		}

		const selectedMedias = Array.from(e.target.files);
		setImg(selectedMedias[0]);

		var file = selectedMedias[0];

		const objectUrl = URL.createObjectURL(file)
		setImgPreview1(objectUrl)
	};

	async function deleteChat(id){

		await api.delete(`/chatbot/${id}/${whatsAppId}`);
		toast.success("Menu Chat-bot deletado com sucesso!");
		setTimeout(async () => {
			const { data } = await api.get(`/chatbot/${whatsAppId}`);
			setChatbots(data)
		}, 1000)

	}

	const handleChat = (id) => {

		const result =  chatsbots.filter(c => {
			return c.id === id;
		})

		if (!result[0]){
			return toast.warning('Erro ao buscar!')
		}
		const res = result[0] ?? ''

		const resultUsers =  queue.filter(c => {
			return c.id === res.queue_id;
		})

		setUsersfull(resultUsers[0].users)

		setId(res.id);
		setImgPreview1(res.img);
		setMsg(res.msg);
		setImg(res.img);
		setOption(res.option);
		setName(res.name);
		setKeysname(res.keys_name);
		setTagselect(res.tagticket_id);
		setQueueselect(res.queue_id);
		setUserselect(res.user_id);
		setNewsletter(res.newsletter);
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				style={{width:'80%'}}
				fullScreen={true}
				maxWidth="lg"
				scroll="paper"
			>
				<DialogTitle >
					{whatsAppId
						? "Chat-bot "+whatsApp.name
						: ''}

				</DialogTitle>


			<DialogContent dividers >
				<h3 style={{marginTop:0}}>Conteúdo da Mensagem</h3>
				<div style={{ position:'absolute', right:30, top:15}}>
					<FormControlLabel
						control={
							<Switch
								checked={chatbot_active}

								onChange={(e)=> setChatbotactive(e.target.checked)}
								name="chatbot_active"
								color="primary"
							/>
						}
						label="Ativar Chat-bot"
					/>
				</div>
				<div className={classes.multFieldLine}>
							<div style={{display:'flex',marginTop:20, flexDirection:'column'}}>


								<div style={{display:'flex', flexDirection:'column', width:'100%', alignItems:'flex-end',justifyContent:'space-between' }}>
									<input
										style={{opacity: 0}}
										type="file"
										id="upload-button"
										className={classes.uploadInput}
										onChange={handleChangeMedias}
									/>
									<div style={{display:'flex', flexDirection:'row', width:'100%', marginRight:20}}>
									<div style={{display:'flex', flexDirection:'column',alignItems:'center',justifyContent:'center', width:'25%'}}>

										<label htmlFor="upload-button" style={{}}>
											<IconButton
												aria-label="upload"
												component="span"
												style={{borderRadius:10, alignItems:"center", justifyContent:'center',padding:0,  marginRight:20}}
											>

												<CropOriginal className={classes.sendMessageIcons} style={{width:'150px', height:'150px',display: imgPreview ? 'none' : 'block'}}/>
												<Tooltip title="Se optar por esta opção a mensagem de apresentação será substituida pela mídia que enviar aqui." >
													<Info style={{marginTop:-100, marginLeft:-10, opacity: imgPreview ? 0 : 1}}/>
												</Tooltip>
											</IconButton>

										</label>
										<div>
											<img src={imgPreview} style={{width:'120px', height:'120px',textAlign:'center',display: imgPreview ? 'block' : 'none'}}/>
											<Typography  style={{cursor:'pointer', display: imgPreview ? 'block' : 'none', textAlign:'center', marginTop:10}} onClick={() => {
												setImgchatbot('');
												setImgPreview('');
											}}>Remover</Typography>
										</div>

									</div>

										<TextField
											fullWidth={'50%'}
											label={''}
											minRows={5}
											variant="outlined"
											value={msg_chatbot}
											multiline={true}
											style={{marginRight:5}}
											onChange={(e) => setMsgchatbot(e.target.value)}
										/>
										<Tooltip title="Programe o que será enviado automaticamente quando o contato enviar uma mensagem." >
											<Info style={{marginTop:-15, marginLeft:-20}}/>
										</Tooltip>
									</div>
									<div style={{display:'flex', flexDirection:'row', width:'100%', alignItems:'center',justifyContent:'space-between' }}>
										<p style={{width:'75%'}}>Dicas: Utilize a variável %NAME% para exibir o nome do lead na mensagem. Exiba palavras em negrito utilizando *antes e depois do texto
											desejado. Por exemplo, digite: *Texto*  Resultado: <b>Texto</b></p>
										<IconButton
											style={{position: 'relative'}}
											aria-label="emojiPicker"
											component="span"
											onClick={(e) => setShowEmoji((prevState) => !prevState)}
										>
											<MoodIcon className={classes.sendMessageIcons} />
										</IconButton>
									</div>

									{showEmoji && (
										<div className={classes.emojiBox}>
											<ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
												<Picker
													perLine={8}
													showPreview={false}
													showSkinTones={false}
													onSelect={(e) => handleAddEmoji(e, 'msg_chatbot')}
												/>
											</ClickAwayListener>
										</div>
									)}

								</div>

								<DialogActions>
									<Button
										onClick={handleClose}
										color="secondary"
										disabled={''}
										variant="outlined"
									>
										{i18n.t("whatsappModal.buttons.cancel")}
									</Button>
									<Button
										type="submit"
										onClick={handleSaveWhatsApp}
										color="primary"
										disabled={''}
										variant="contained"
										className={classes.btnWrapper}
									>
										Salvar
									</Button>
								</DialogActions>

							</div>


				</div>
				<Divider/>
				<h3>Menus do Bot 	<Tooltip title="Configure as opções que serão exibidas ao contato. O contato deverá digitar a opção desejada para ser direcionado à um departamento ou conteúdo que você determinar abaixo." >
					<Info/>
				</Tooltip>
				</h3>
				<DialogActions style={{marginTop:-50, marginBottom:50}}>
					<Button
						type="submit"
						onClick={newChatBot}
						color="primary"
						disabled={''}
						variant="contained"
						className={classes.btnWrapper}
					>
						Novo Chat-bot
					</Button>
				</DialogActions>


				<div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-between'}}>


					<div style={{width:'30%'}}>

								<List>
									{chatsbots && chatsbots.length > 0 && chatsbots.map(c => (
										<ListItem key={c.id} >
											<ListItemAvatar
												onClick={()=>handleChat(c.id)}
												style={{cursor:'pointer'}}>
												<Avatar style={{backgroundColor: id === c.id ? '#2576d2' : '' }}>
													<ArrowForward style={{backgroundColor: id === c.id ? '#2576d2' : '' }} />
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												onClick={()=>handleChat(c.id)}
												style={{cursor:'pointer'}}
												primary={`${c.option} ${c.name}`}
											/>
											<ListItemSecondaryAction>
												<IconButton edge="end" aria-label="delete" onClick={() => deleteChat(c.id)}>
													<DeleteIcon />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))}
									{chatsbots && chatsbots.length === 0 && (
										<ListItem >
											<ListItemText
												style={{cursor:'pointer'}}
												primary={'Crie seu primeiro menu ao lado'}
											/>
										</ListItem>
									)}
								</List>

					</div>

					<div style={{width:'70%', display:'flex', flexDirection:'column'}}>

						<div style={{width:'100%', display:'flex', flexDirection:'row'}}>
							<div style={{width:'20%'}}>
								<div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'start'}}>
									<TextField
										fullWidth={'50%'}
										label={'Opção'}
										variant="outlined"
										type={'number'}
										value={option}
										style={{marginRight:5}}
										onChange={(e) => setOption(e.target.value)}
									/>
									<Tooltip title="Digite o número correspondente à essa opção." >
										<Info style={{marginTop:-15, marginLeft:-20}}/>
									</Tooltip>
								</div>
							</div>
							<div style={{display:'flex', flexDirection:'column', width:'80%', alignItems:'flex-end'}}>
								<div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'start'}}>
									<TextField
										fullWidth={'50%'}
										label={'Legenda'}
										variant="outlined"
										value={name}
										style={{marginRight:5}}
										onChange={(e) => setName(e.target.value)}
									/>
									<Tooltip title="Digite o título ou legenda dessa opção." >
										<Info style={{marginTop:-15, marginLeft:-20}}/>
									</Tooltip>
								</div>
								<IconButton
									style={{position: 'relative'}}
									aria-label="emojiPicker"
									component="span"
									onClick={(e) => setShowEmoji2((prevState) => !prevState)}
								>
									<MoodIcon className={classes.sendMessageIcons} />
								</IconButton>
								{showEmoji2 && (
									<div className={classes.emojiBox}>
										<ClickAwayListener onClickAway={(e) => setShowEmoji2(false)}>
											<Picker
												perLine={8}
												showPreview={false}
												showSkinTones={false}
												onSelect={(e) => handleAddEmoji(e, 'name')}
											/>
										</ClickAwayListener>
									</div>
								)}

							</div>
						</div>

						<div style={{width:'100%', marginTop:15}}>
							<div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'start'}}>
								<TextField
									fullWidth={'50%'}
									label={'Palavras Chave '}
									variant="outlined"
									type={'text'}
									value={keys_name}
									style={{marginRight:5}}
									onChange={(e) => setKeysname(e.target.value)}
								/>
								<Tooltip title="Utilize o campo abaixo para que contato possa identificar o menu com palavras-chave, separe sempre por vírgula." >
									<Info style={{marginTop:-15, marginLeft:-20}}/>
								</Tooltip>
							</div>
						</div>

						<div style={{display:'flex', marginTop:20, flexDirection:'row', width:'100%', alignItems:'flex-end',justifyContent:'space-between' }}>

							<div style={{display:'flex', flexDirection:'column',alignItems:'center',justifyContent:'center', width:'25%'}}>
								<input
									style={{opacity: 0}}
									type="file"
									id="upload-button1"
									className={classes.uploadInput}
									onChange={handleChangeMedias1}
								/>
								<label htmlFor="upload-button1" style={{marginTop:-230}}>
									<IconButton
										aria-label="upload"
										component="span"
										style={{borderRadius:10, alignItems:"center", justifyContent:'center',padding:0,  marginRight:20}}
									>

										<CropOriginal className={classes.sendMessageIcons} style={{width:'150px', height:'150px',display: imgPreview1 ? 'none' : 'block'}}/>
										<Tooltip title="Se optar por esta opção a mensagem de apresentação será substituida pela mídia que enviar aqui." >
											<Info style={{marginTop:-100, marginLeft:-10, opacity: imgPreview1 ? 0 : 1}}/>
										</Tooltip>
									</IconButton>

								</label>
								<div>
									<img src={imgPreview1} style={{width:'120px', height:'120px',textAlign:'center',display: imgPreview1 ? 'block' : 'none'}}/>
									<Typography  style={{cursor:'pointer', display: imgPreview1 ? 'block' : 'none', textAlign:'center', marginTop:10}} onClick={() => {
										setImg('');
										setImgPreview1('');
									}}>Remover</Typography>
								</div>

							</div>


							<div style={{width:'70%', flexDirection:'column', display:'flex', alignItems: 'flex-end'}}>
								<div style={{display:'flex', flexDirection:'row', width:'100%'}}>
									<TextField
										fullWidth={'50%'}
										label={'Mensagem (texto e/ou mídia)'}
										minRows={5}
										variant="outlined"
										value={msg}
										multiline={true}
										style={{marginRight:5}}
										onChange={(e) => setMsg(e.target.value)}
									/>
									<Tooltip title="Digite uma mensagem ou anexe uma mídia para ser enviada ao Contato." >
										<Info style={{marginTop:-15, marginLeft:-20}}/>
									</Tooltip>
								</div>

								<IconButton
									style={{position: 'relative'}}
									aria-label="emojiPicker"
									component="span"
									onClick={(e) => setShowEmoji3((prevState) => !prevState)}
								>
									<MoodIcon className={classes.sendMessageIcons} />
								</IconButton>


								{showEmoji3 && (
									<div className={classes.emojiBox}>
										<ClickAwayListener onClickAway={(e) => setShowEmoji3(false)}>
											<Picker
												perLine={8}
												showPreview={false}
												showSkinTones={false}
												onSelect={(e) => handleAddEmoji(e, 'msg')}
											/>
										</ClickAwayListener>
									</div>
								)}
							</div>


							</div>



						<div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
							<div style={{width:'23%'}}>
								<InputLabel id="demo-simple-select-label">Departamento</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={queueSelect}
									onChange={(e) => handleQueueSelect(e.target.value)}
									style={{width:'100%'}}
								>
									{queue.length > 0 && queue.map(q => (
										<MenuItem key={q.id} value={q.id}>{q.name}</MenuItem>
									))}

								</Select>
							</div>
							<div style={{width:'23%'}}>
								<InputLabel id="demo-simple-select-label">Atendente</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={userSelect}
									onChange={(e) => setUserselect(e.target.value)}
									style={{width:'100%'}}
								>
									<MenuItem value={''}>Todos</MenuItem>
									{usersfull && usersfull.length > 0 && usersfull.map(q => (
										<MenuItem key={q.id} value={q.id}>{q.name}</MenuItem>
									))}

								</Select>
							</div>

							<div style={{width:'23%'}}>
								<InputLabel id="demo-simple-select-label">Tag (Etiqueta)</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={tagSelect}
									onChange={(e) => setTagselect(e.target.value)}
									style={{width:'100%'}}
								>
									{tagsFull && tagsFull.length > 0 && tagsFull.map(q => (
										<MenuItem key={q.id} value={q.id}>{q.name}</MenuItem>
									))}

								</Select>
							</div>
							<div style={{width:'23%'}}>
								<InputLabel id="demo-simple-select-label22">Newsletter</InputLabel>
								<Select
									labelId="demo-simple-select-label22"
									id="demo-simple-select22"
									value={newsletter}
									onChange={(e) => setNewsletter(e.target.value)}
									style={{width:'100%'}}
								>
										<MenuItem value={0}>Não inscrito</MenuItem>
										<MenuItem value={1}>Inscrito</MenuItem>

								</Select>
							</div>
						</div>
					</div>

				</div>
				<DialogActions style={{marginTop:20}}>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={''}
						variant="outlined"
					>
						{i18n.t("whatsappModal.buttons.cancel")}
					</Button>
					<Button
						type="submit"
						onClick={handleSaveChatbot}
						color="primary"
						disabled={''}
						variant="contained"
						className={classes.btnWrapper}
					>
						Salvar
					</Button>
				</DialogActions>
			</DialogContent>


			</Dialog>
		</div>
	);
};

export default React.memo(ChatBotModal);
