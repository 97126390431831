import React, {useContext, useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {format,  parseISO} from "date-fns";
import api from "../../services/api";
import {AuthContext} from "../../context/Auth/AuthContext";
import {toast} from "react-toastify";
import { Scheduler } from "@aldabil/react-scheduler";
import { ptBR } from 'date-fns/locale'
import toastError from "../../errors/toastError";
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    // // backgroundColor: "#eee",
    // padding: theme.spacing(4),
    height: `calc(100% - 48px)`,
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Schedule = () => {
  const [users, setUser] = useState(null);
  const [events, setEvents] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const { user } = useContext(AuthContext);

  const classes = useStyles();

  const [pageNumber, setPageNumber] = useState(1);

  const searchParam = '';

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {

          const { data } = await api.get("/users/", {
            params: { searchParam, pageNumber,  company_id: user?.company_id },
          });

          let userFull = [];

          data.users.map((u =>{
            var mobile = u.mobile ?? 'Sem telefone para envio!'
            userFull.push({ id: u.id, text: u.name+' - '+mobile, value: u.id })
          }))

          setUser(userFull)
          setHasMore(data.hasMore);
          setLoading(false);


        } catch (err) {
          toastError(err);
        }
      };
      fetchUsers();
    }, 200);
    return () => clearTimeout(delayDebounceFn);
  }, []);


  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchEvents = async () => {
        try {

          if(user.profile === 'admin'){
            const res = await api.get("/events/", {
              params: { user_id: user?.id,  company_id: user?.company_id, profile:user.profile },
            });
            let eventsFull = [];

            if (res.data){
              res.data.map(e => {
                eventsFull.push({
                  event_id: e.event_id,
                  id: e.event_id,
                  warn: e.warn,
                  title: e.title,
                  description: e.description,
                  color: e.whatsapp_id ? '#005B41' : '#0766AD',
                  editable: e.whatsapp_id ? false : true,
                  user_id: e.user_id,
                  start: new Date(e.start),
                  end: new Date(e.end),
                })
              })
            }

            setEvents(eventsFull)

          }else{
            const res = await api.get("/events/", {
              params: { user_id: user?.id,  company_id: user?.company_id, profile:user.profile },
            });
            let eventsFull = [];

            if (res.data){
              res.data.map(e => {
                eventsFull.push({
                  event_id: e.event_id,
                  id: e.event_id,
                  title: e.title,
                  warn: e.warn,
                  description: e.description,
                  color: e.whatsapp_id ? '#005B41' : '#0766AD',
                  editable: e.whatsapp_id ? false : true,
                  user_id: e.user_id,
                  start: new Date(e.start),
                  end: new Date(e.end),
                })
              })
            }

            setEvents(eventsFull)
          }

        } catch (err) {
          toastError(err);
        }
      };
      fetchEvents();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);


  async function onConfirm(event, action){
    event.company_id = user.company_id
    const res = await api.post('events', event)
    event.event_id = res.data.event_id;
    toast.success('Tarefa salva com sucesso!')
    return event;
  }

  const onDelete = async (deletedId)=> {
    console.log(deletedId)
    const res = await api.delete('/events/'+deletedId)
    toast.success('Tarefa deletada com sucesso!')
    return deletedId;
  };



  const translation = {
    navigation: {
      month: "Mês",
      week: "Semana",
      day: "Dia",
      today: "Hoje"
    },
    form: {
      addTitle: "Adicionar Terefa",
      editTitle: "Editar Tarefa",
      confirm: "Confirmar",
      delete: "Deletar",
      cancel: "Cancelar"
    },
    event: {
      title: "Titulo",
      start: "Inicio",
      end: "Final",
      allDay: "All Day"
    },
    validation: {
      required: "Required",
      invalidEmail: "Invalido Email",
      onlyNumbers: "Only Numbers Allowed",
      min: "Minimum {{min}} letters",
      max: "Maximum {{max}} letters"
    },
    moreEvents: "More...",
    loading: "Loading..."
  }


  return (
    <div style={{padding:20}} className={classes.chatContainer}>
      {events && user && (
          <Scheduler
              editable={true}
              deletable={true}
              delete
              dialogMaxWidth={'sm'}
              view="month"
              translations={translation}
              locale={ptBR}
              style={{width:'100%'}}
              height={570}
              onConfirm={onConfirm}
              hourFormat={24}
              onDelete={onDelete}
              events={events}
              viewerExtraComponent={(fields, event) => {
                return (
                    <div>
                      <p
                          style={{ display: "flex", alignItems: "center" }}
                          color="textSecondary"
                          variant="caption"
                          noWrap
                      >
                       <CommentIcon style={{marginRight:10}} /> {event.description}
                      </p>
                    </div>
                );
              }}
              fields={[
                {
                  name: "user_id",
                  type: "select",
                  // Should provide options with type:"select"
                  options: users,
                  config: { label: "Nome do destinatário", required: true, errMsg: "Selecione um usuario" }
                },
                {
                  name: "warn",
                  type: "select",
                  // Should provide options with type:"select"
                  options: [{ id: 1, text: 'Sim', value: 1 },{ id: 2, text: 'Não', value: 2 }],
                  config: { label: "Avisar via mensagem", required: true, errMsg: "Selecione uma opção" }
                },
                {
                  name: "description",
                  type: "input",
                  default: "",
                  config: { label: "Descrição", multiline: true, rows: 4 }
                },
              ]}

          />
      )}
    </div>
  );
};



export default Schedule;
