import api from "../../services/api";

const useQueues = (company_id, ticketWhatsappId = '') => {

    if (ticketWhatsappId){
        var findAll = async () => {
            const { data } = await api.get("/whatsappqueue/"+ticketWhatsappId);
            return data;
        }
    }else{
        var findAll = async () => {
            const { data } = await api.get("/queue/"+company_id);
            return data;
        }
    }



	return { findAll };
};

export default useQueues;
