import React, {useContext, useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import {AuthContext} from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const QueueSelect = ({ selectedQueueIds, onChange, whatsappId, type }) => {
	const classes = useStyles();
	const [queues, setQueues] = useState([]);
	const { user } = useContext(AuthContext);



	useEffect(() => {
		(async () => {
			try {
				if (whatsappId){
					const { data } = await api.get("/queue/"+user.company_id);

					if (user.profile === 'admin'){
						if (data){
							var res = data.filter(r => {
								return r.whatsapps[0] && r.whatsapps[0].id === whatsappId
							})
						}else{
							var res = []
						}
					}else{

						var newValue = data.filter(r => {
							return r.whatsapps[0] && r.whatsapps[0].id === whatsappId
						})

						var res = user.queues && user.queues.length > 0 && user.queues.filter(q => {
							return newValue.find(all => {
								return q.id === all.id
							})
						})
					}


					setQueues(res);
				}
			} catch (err) {
				toastError(err);
			}
		})();
	}, [whatsappId]);

	const handleChange = e => {
		if (type && selectedQueueIds && selectedQueueIds.length > 0){
			const sel = e.target.value
			setTimeout(() => onChange([sel.pop()]) ,100)
		}else{
			onChange(e.target.value);
		}
	};

	return (
		<div style={{ marginTop: 6 }}>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel>Departamentos</InputLabel>
				<Select
					multiple
					labelWidth={110}
					value={selectedQueueIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
					renderValue={selected => (
						<div className={classes.chips}>
							{selected?.length > 0 &&
								selected.map(id => {
									const queue = queues.find(q => q.id === id);
									return queue ? (
										<Chip
											key={id}
											style={{ backgroundColor: queue.color }}
											variant="outlined"
											label={queue.name}
											className={classes.chip}
										/>
									) : null;
								})}
						</div>
					)}
				>
					{queues.map(queue => (
						<MenuItem key={queue.id} value={queue.id}>
							{queue.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default QueueSelect;
