import React, {useState, useEffect, useRef, useContext} from "react";
import { useTheme } from "@material-ui/core/styles";
import {
	PieChart,
	Tooltip,
	Cell,Pie,
	ResponsiveContainer,
} from "recharts";
import { startOfHour, parseISO, format } from "date-fns";

import Title from "./Title";
import api from "../../services/api";
import {AuthContext} from "../../context/Auth/AuthContext";

const Chart2 = () => {
	const [chartData, setChartData] = useState([]);
	const theme = useTheme();
	const {user} = useContext(AuthContext);

	const date = useRef(new Date().toISOString());

	useEffect(() => {
		async function get(){
			var company_id = user.company_id;
			const res = await api.get("/ticketsOrigem", {
				params: {
					company_id,
					profile : user.profile,
					userId:user.id
				},
			})

			let result = [];

			res.data.result.forEach(ticket => {
				result.push({
					"name": ticket.origem,
					"value": ticket.count
				})
			});

			setChartData(result);

		}
		get();
	},[])


	const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name,  }, ) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<>
				<text x={x} y={y} fill="#4d4d4d" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
					{`${(percent * 100).toFixed(0)}% ${name}`}
				</text>
			</>
		);
	};


	return (
		<React.Fragment>
			<Title style={{ color:'#4d4d4d'}}>
				Origem dos Tickets
			</Title>
			<ResponsiveContainer >
				<PieChart
					width={730}
					height={700}
				>
					<Pie
						data={chartData}
						cx="50%"
						cy="50%"
						labelLine={false}
						label={renderCustomizedLabel}
						outerRadius={80}
						fill="#8884d8"
						dataKey="value"
						isAnimationActive={false}
					>
						<Tooltip />
						{chartData.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
						))}
					</Pie>

				</PieChart>
			</ResponsiveContainer>
		</React.Fragment>
	);
};

export default Chart2;
