import React, { useContext, useEffect, useState} from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import { i18n } from "../../translate/i18n";

import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import {AuthContext} from "../../context/Auth/AuthContext";
import api from "../../services/api";
import {toast} from "react-toastify";
import {format, parseISO} from "date-fns";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: "#eee",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "#eee",
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 160,
		height: 160,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
}));

const ContactDrawerKanban = ({ open, contact, statusKanban, ticketId, moneyTicket, origemTicket, ticket }) => {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const [modalOpen, setModalOpen] = useState(false);
	const [money1, setMoney1] = useState(123);
	const [status, setStatus] = useState(statusKanban ?? '');
	const [tagSelect, setTagSelect] = useState(ticket.tagticket_id ?? '');
	const [close_date, setClosedate] = useState(ticket.close_date ?? '');
	const [origem, setOrigem] = useState(origemTicket ?? '');
	const [tags, setTags] = useState(null);

	useEffect(() => {
		setStatus(statusKanban);
		setOrigem(origemTicket);
		setTagSelect(ticket.tagticket_id ?? '');
		setClosedate(ticket.close_date ?? '');
		setMoney1(moneyTicket ?? 0);
	}, [ticketId]);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get(`/tagTicket/${ticket.whatsappId}/${ticket.company_id}`);
				setTags(data)
			} catch (err) {
				//toastError(err);
			}
		})();

	}, [ticketId, open]);

	async function handleUpdateTicketTag(value){
		setTagSelect(value)
		await api.put(`/updateTagTicket/${ticketId}`, {
			company_id: user.company_id,
			tagticket_id: value
		})
		toast.success('Ticket alterado com sucesso!')
	}

	async function setDateClose(value){
		setClosedate(value)
		await api.put(`/updateClosedate/${ticketId}`, {
			company_id: user.company_id,
			close_date: value
		})
		toast.success('Ticket alterado com sucesso!')
	}

	async function handleUpdateTicket(value){
				setStatus(value)
				if (user.profile === 'admin'){
					await api.put(`/updateKanban/${ticketId}`, {
						kanban_admin: value,
						userId: user.id || null,
					})
				}else{
					await api.put(`/updateKanban/${ticketId}`, {
						kanban_user: value,
						userId: user.id || null,
					})
				}
				toast.success('Ticket alterado com sucesso!')
	}

	async function handleUpdateTicketValue(){
		await api.put(`/updateKanbanValue/${ticketId}`, {
			value: money1
		})
				toast.success('Ticket alterado com sucesso!')
	}

	async function handleOrigemTicket(value){
		setOrigem(value)
		await api.put(`/updateKanbanOrigem/${ticketId}`, {
			origem: value
		})
				toast.success('Ticket alterado com sucesso!')
	}

	function toMoney(values){
		if (values.length > 0){
			var valor = values + '';
			valor = parseInt(valor.replace(/[\D]+/g, ''))
			valor = valor+'';
			valor = valor.replace(/([0-9]{2})$/g, ".$1");

			if (valor.length > 6){
				valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
			}
		}else{
			valor = '0.00'
		}

		document.getElementById('money').value = valor
		setMoney1(valor)
	}


	return (
		<div className={classes.content}>
			<Paper square variant="outlined" className={classes.contactHeader}>
				<Avatar
					alt={contact.name}
					src={contact.profilePicUrl}
					className={classes.contactAvatar}
				></Avatar>

				<Typography>{contact.name}</Typography>
				<Typography>
					<Link href={`tel:${contact.number}`}>{contact.number}</Link>
				</Typography>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => setModalOpen(true)}
				>
					{i18n.t("contactDrawer.buttons.edit")}
				</Button>
			</Paper>
			<Paper square variant="outlined" className={classes.contactDetails}>
				<ContactModal
					open={modalOpen}
					onClose={() => setModalOpen(false)}
					contactId={contact.id}
				></ContactModal>
				<Typography style={{color:'blue', marginTop:5, marginBottom:10}}>Data criação {format(parseISO(ticket.createdAt), "dd/MM/yyyy - HH:mm")}</Typography>
				<InputLabel >Previsão de fechamento</InputLabel>
				<FormControl fullWidth className={classes.margin} variant="outlined" style={{ marginBottom:10}}>

					<OutlinedInput
						id="fechamento"
						value={close_date}
						onChange={(e) => setDateClose(e.target.value)}
						type={'date'}
						labelWidth={220}
					/>
				</FormControl>

				<FormControl variant="outlined" className={classes.maxWidth}>
					<InputLabel>Etiqueta</InputLabel>
					<Select
						value={tagSelect}
						onChange={(e) => handleUpdateTicketTag(e.target.value)}
						label={'Alterar etiqueta'}
					>
						<MenuItem value={0}>Sem etiqueta</MenuItem>
						{tags && tags.map((t) => (
							<MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
						))}
					</Select>

				</FormControl>

				<FormControl variant="outlined" className={classes.maxWidth} style={{marginTop:10}}>
					<InputLabel>Origem ticket</InputLabel>
					<Select
						value={origem}
						label={'Alterar origem'}
						onChange={(e) => handleOrigemTicket(e.target.value)}
					>
						<MenuItem value={''}>&nbsp;</MenuItem>
						<MenuItem value={'whatsapp'}>Whatsapp</MenuItem>
						<MenuItem value={'google'}>Google</MenuItem>
						<MenuItem value={'redes sociais'}>Redes sociais</MenuItem>
						<MenuItem value={'pessoalmente'}>Pessoalmente</MenuItem>
						<MenuItem value={'outros'}>Outros</MenuItem>

					</Select>

				</FormControl>
				<Typography style={{color:'tomato', marginTop:10}}>Negociação R$ {money1}</Typography>
				<FormControl fullWidth className={classes.margin} variant="outlined" style={{marginTop:10, marginBottom:5}}>
					<InputLabel htmlFor="money">Alterar valor negociação</InputLabel>
					<OutlinedInput
						id="money"

						onKeyUp={(e) => toMoney(e.target.value)}
						startAdornment={<InputAdornment position="start">R$</InputAdornment>}
						labelWidth={180}
					/>
				</FormControl>
				<Button
					variant="contained"
					color="primary"
					onClick={()=> handleUpdateTicketValue() }
				>
					Alterar negociação
				</Button>

				<Typography variant="subtitle1">
					{i18n.t("contactDrawer.extraInfo")}
				</Typography>
				{contact?.extraInfo?.map(info => (
					<Paper
						key={info.id}
						square
						variant="outlined"
						className={classes.contactExtraInfo}
					>
						<InputLabel>{info.name}</InputLabel>
						<Typography component="div" noWrap style={{ paddingTop: 2 }}>
							<MarkdownWrapper>{info.value}</MarkdownWrapper>
						</Typography>
					</Paper>
				))}
			</Paper>
		</div>
	);
};

export default ContactDrawerKanban;
