import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {IconButton, Tooltip} from "@material-ui/core";
import { MoreVert, Replay, Close, Update } from "@material-ui/icons";
import NewScheduleModal from '../NewScheduleModal'
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));

const TicketActionButtons = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};


	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
			});

			setLoading(false);

			if (status === 'closed'){
				document.getElementById('ticket'+ticket.id).style.display = 'none'
			}

			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			//toastError(err);
		}
	};

	return (
		<div className={classes.actionButtons}>
			{/*{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}*/}
			<NewScheduleModal
				ticket={ticket}
				modalOpen={modal}
				onClose={() => setModal(false)}
			/>
			{ticket.status === "open" && (
				<>
                <Tooltip title="Agendar envio de mensagem">
					<IconButton
						style={{color:'#508D69'}}
						loading={loading}
						onClick={() => setModal(true)}
					>
						<Update />
					</IconButton>
				</Tooltip>
				<Tooltip title="Voltar ticket">
					<IconButton
						style={{color:'#3b72ee'}}
						loading={loading}
						onClick={e => handleUpdateTicketStatus(e, "pending", null)}
					>
						<Replay />
					</IconButton>
				</Tooltip>

					<Tooltip title="Finalizar ticket">
					<IconButton
						style={{ color:'#de3d50'}}
						loading={loading}
						onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
					>
						<Close />
					</IconButton>
				</Tooltip>

					<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div>
	);
};

export default TicketActionButtons;
