import React, {useContext, useEffect, useState, useCallback} from "react";
import {useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Board from 'react-trello'
import AttachMoney from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import MoneyOff from '@mui/icons-material/MoneyOff';
import useTickets from "../../hooks/useTickets";
import {format,  parseISO} from "date-fns";
import api from "../../services/api";
import {AuthContext} from "../../context/Auth/AuthContext";
import Avatar from "@material-ui/core/Avatar";
import {toast} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ModalKanban from './modalKanban'
import ModalTag from './modalTags'
import toastError from "../../errors/toastError";
import style from "./styles.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import NewTicketModal from "../../components/NewTicketModal";



const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    // // backgroundColor: "#eee",
    // padding: theme.spacing(4),
    height: `calc(100% - 48px)`,
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Kanban = () => {
  const {user} = useContext(AuthContext);

  const classes = useStyles();

  const [modal, setModal] = useState(false);
  const [modalTag, setModaltag] = useState(false);

  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(100);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [lanes, setLanes] = useState([]);
  const [tags, setTags] = useState([]);
  const [colors, setColors] = useState([]);
  const [data, setData] = useState({lanes : []});
  const [position, setPosition] = useState('');
  const [ticket, setTicket] = useState('');
  const [ticketfull, setTicketfull] = useState('');
  const [editKanban, setEdit] = useState('');

  const [status, setStatus] = useState('open');
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);

  const [search, setSearch] = useState('');
  const [search1, setSearch1] = useState('Todos');
  const [search2, setSearch2] = useState('Todos');
  const [search3, setSearch3] = useState('Todos');

  const [dateI, setDatei] = useState('');
  const [dateF, setDatef] = useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showAll = true;

  const searchParam = '';
  const selectedQueueIds = [];

  const {  count } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    queueIds: JSON.stringify(selectedQueueIds),
  });

  useEffect( () => {
    kanbans()
        // make sure to catch any error
        .catch(console.error);;
  },[])

  useEffect( () => {
    async function getUsers(){
      const res = await api.get('/users', {
        params:{company_id:user.company_id}
      });
      setUsers(res.data.users);
    }
    getUsers()
  },[])


  function colorStatusKanban(status){
    switch(status) {
      case "Em andamento":
        return '#238af3'
      case "Vendido":
        return '#3a754f'
      case "Perdido":
        return '#B80000'
      case "Pausado":
        return '#FF9800'
      case "Não pausado":
        return '#575757'
      case "Nova":
        return '#0026ce'
      }
  }


  const kanbans = useCallback(async () => {
    try{
      const res =  await api.get(`/kanbans/${user.id}/${user.company_id}`)
      const set = [];
      const contactsFull = [];
      const setTagsFull = [];

      setPosition(res.data.kanbans.length)
      setColors(res.data.colors)

      res.data.kanbans.map(async (k, i) => {
        const company_id = user.company_id
        const user_id = user.id
        const profile = user.profile
        const status = k.name

        const result = await api.get("/ticketsKanban", {
          params: {
            status,
            user_id,
            company_id,
            profile
          },
        })

        const sets = [];
        var total = 0;
        result.data.tickets.map((t => {
          if (t.tags){
            setTagsFull.push(t.tags)
          }

          total = (parseFloat(total) + parseFloat(t.value));

          if (t.value > 0){
            var value = parseFloat(t.value);
             value = value.toLocaleString('pt-br', {style: 'currency', currency:'brl'})
          }else{
            var value = 0
          }


          contactsFull.push(`${t.contact.name} - ${t.contact.number}`)

          var d1 = new Date(t.createdAt);
          var d2 = t.close_date ? new Date(t.close_date).getTime() : 0;

          sets.push({
            id: `${t.id}#${d1.getTime()}#${d2}`,
            title: <>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <Avatar style={{marginRight:8,width:29,height:29}} src={t?.contact?.profilePicUrl} />
                <span style={{fontSize:12.5, color:'#313131',}}>{t.contact.name} - {t.contact.number}</span>
              </div>
              <div id={'neg'+t.id} style={{fontSize:13,color:'#313131', marginTop:5, display:'flex', flexDirection:'row', alignItems:'center'}}>
                <div style={{width:15,height:15,marginRight:5, backgroundColor: colorStatusKanban(t.status_kanban)}}></div>
                {t.status_kanban}
              </div>
              <div  style={{fontSize:13,color:'#313131',fontWeight:'normal', marginTop:5, display:'flex', flexDirection:'row', alignItems:'center'}}>
                {value === 0 ? <MoneyOff/> : <AttachMoney/>}
                <span id={'value'+t.id}>  {value === 0 ? 'Nenhum produto' : value}</span>
              </div>

            </>,
            description: <>
              <span>{t.lastMessage}</span>
             <span style={{opacity:0}}>{t.user && t.user.name ? t.user.name : ''}</span>
            </>,
            label:<>
              <p>Criação: {format(parseISO(t.createdAt), "dd/MM/yyyy")}</p>
              <span id={'previsao'+t.id}> Previsão: {t.close_date ? format(parseISO(t.close_date), "dd/MM/yyyy") : '-'}</span>

            </>,
            draggable: true,
            hideCardDeleteIcon: true,
            editable: false,
            editLaneTitle: false,
            tags: t.tags
          })
        }));

        if (total > 0){
          total = total.toLocaleString('pt-br', {style: 'currency', currency:'brl'})
        }else{
          total = 'R$ 0,00'
        }

        set.push({
          position: k.position,
          idKanban: k.id,
          id: k.name,
          title: <span style={{fontSize:12}}>{k.name.toUpperCase()} ({sets.length})</span>,
          label:( k.name !== 'inicial' && user.profile === 'admin') ? (
              <div style={{display:'flex', flexDirection:'row',width:'100%', alignItems:'center', }}>
                <span style={{fontSize:11, marginRight:3}}>{total}</span>
                <EditIcon style={{cursor:'pointer', fontSize:15, marginRight:3}} onClick={() => editLane(k)} />
                <ClearIcon style={{cursor:'pointer', fontSize:15}} onClick={() => onLaneDelete(k.name)}/>
              </div>
          ) : '',
          cards:  sets,
          style:{height:'80vh'}
        })
      })

      setData({lanes: set });
      setContacts(contactsFull);
      setLanes(set);
      setTags(setTagsFull)


    } catch (err) {
      toastError(err)
    }
  }, [])

  const handleDragEnd = async (cardId, sourceLaneId, targetLaneId) => {
    if (sourceLaneId !== targetLaneId){
      if (user.profile === 'admin'){
        await api.put(`/updateKanban/${cardId}`, {
          kanban_admin: targetLaneId,
          userId: user.id || null,
        })
      }else{
        await api.put(`/updateKanban/${cardId}`, {
          kanban_user: targetLaneId,
          userId: user.id || null,
        })
      }
      toast.success('Ticket alterado com sucesso!')
    }
  }

  const openTagsModal = async (cardId, metadata, laneId) => {
    var dataverify = cardId.split('#')

   setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + dataverify[0]);
          setTicketfull(data)
          setModaltag(true)
          setTicket(dataverify[0])
        } catch (err) {

          toastError(err);
        }
      };
      fetchTicket();
    }, 500);
  }

  const onLaneAdd = (params) => {
    kanbans();
  }

  const onLaneDelete = async (params) => {

    if (window.confirm('Deseja mesmo deletar?')){
      if (params === 'inicial'){

        return toast.warning('A coluna inicial não pode excluir!')
      }else{
        await api.delete(`/deleteKanban/${params}`, {
          params:{
            company_id: user.company_id,
            name: params,
            profile: user.profile,
            user_id: user.id || null,
          }
        })

        toast.success('Excluido com sucesso!');
        setTimeout(() => window.location.reload(),1300)
      }
    }
  }

  const handleLaneDragEnd = async (removedIndex, addedIndex, payload) => {
    if (user.profile === 'user'){
      return toast.warning('Somente admin altera posição!')
    }
    await api.put(`/updateKanbanPosition/${payload.idKanban}`, {
      positionFinal: addedIndex,
      positionInicial: removedIndex,
      user_id: user.id || null,
      company_id: user.company_id,
    })
    toast.success('Posição alterada com sucesso!')
  }

  const handleSearch = (params, type = null) => {
    if (type === 'livre'){
      setSearch(params)
    }
    if (type === 'status'){
      setSearch1(params)
      params = params === 'Todos' ? '' : `${params}`;
    }
    if (type === 'contatos'){
      setSearch2(params)
      params = params === 'Todos' ? '' : `${params}`;
    }
    if (type === 'atendentes'){
      setSearch3(params)
      params = params === 'Todos' ? '' : `${params}`;
    }

    if (type === 'dateI' || type === 'dateF'){
      if (type === 'dateI'){
        setDatei(params)
        var d1 =  new Date(params).getTime();
        document.querySelector("article").style.display = "block";
        var res = document.querySelectorAll("article");
        res.forEach(function(e){
          var dataverify = e.dataset.id.split('#')
          if(parseInt(dataverify[1]) <= parseInt(d1)){
            e.style.display = "none";
          }else{
            e.style.display = "block";
          }

        });
      }else{
        setDatef(params)
        var d2 =  new Date(params).getTime();
        var d1 =  new Date(dateI).getTime();
        document.querySelector("article").style.display = "block";
        var res = document.querySelectorAll("article");
        res.forEach(function(e){
          var dataverify = e.dataset.id.split('#')
          if( (parseInt(dataverify[1]) <= parseInt(d1)) || ( parseInt(dataverify[2]) <= parseInt(d2) ) ){
            e.style.display = "none";
          }else{
            e.style.display = "block";
          }

        });
      }
    }else{
      var texto = params.toLowerCase();
      document.querySelector("article").style.display = "block";
      var res = document.querySelectorAll("article");
      res.forEach(function(e){
        if(e.innerText.toLowerCase().indexOf(texto) < 0){
          e.style.display = "none";
        }else{
          e.style.display = "block";
        }

      });
    }



  }

  const updateTags = (tagssss) => {

    const result = data;

    for (let i = 0; i < result.lanes.length; i++) {

      const res = result.lanes[i].cards.map(l => {
        return l.tags.map((tt, indice) => {
          if (parseInt(tt.id) === parseInt(tagssss)) {
            l.tags.splice(indice, 1);
          }
        })
      })
    }
  }

  const addTags = (tagssss) => {
    for (let i=0;i<data.lanes.length;i++){
      const res = data.lanes[i].cards.map(l => {
        if (parseInt(l.id) === parseInt(tagssss.ticket_id)){
         return l.tags.push(tagssss);
        }
      })
    }
  }

  const editLane = (id) => {
    setModal(true)
    setEdit(id)
  }

  const addStatus = (status) => {
    for (let i=0;i<data.lanes.length;i++){
      const res = data.lanes[i].cards.map((l, index) => {
        var dataverify = l.id.split('#')
        if (parseInt(dataverify[0]) === parseInt(status.id)){
          var colorV = colorStatusKanban(status.status_kanban);
           return document.getElementById('neg'+dataverify[0]).innerHTML = `
            <div style="width:15px;height:15px;margin-right:5px;background-color: ${colorV} " ></div>
                ${status.status_kanban}`;
        }
      })
    }
  }


  async function handleUpdate(){
    const result = await api.get("/tickets/" + ticket);

    for (let i=0;i<data.lanes.length;i++){
      const res = data.lanes[i].cards.map((l, index) => {
        var dataverify = l.id.split('#')
        if (parseInt(dataverify[0]) === parseInt(result.data.id)){
          if (result.data.value > 0){
            var value = parseFloat(result.data.value);
            value = value.toLocaleString('pt-br', {style: 'currency', currency:'brl'})
          }else{
            var value = 0
          }

          document.getElementById('value'+result.data.id).innerHTML = `
                ${value === 0 ? 'Nenhum produto' : value}`;

          return document.getElementById('previsao'+result.data.id).innerHTML = `
               Previsão: ${result.data.close_date ? format(parseISO(result.data.close_date), "dd/MM/yyyy") : '-'}`;
        }
      })
    }

    setModaltag(false)
  }

  return (
    <div className={classes.chatContainer}>
      <Tooltip title="Novo ticket" aria-label="add">
        <Fab color="primary"
             onClick={() => setNewTicketModalOpen(true)}
             style={{position:'absolute', bottom:15, right:15}}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <NewTicketModal
          modalOpen={newTicketModalOpen}
          onClose={(e) => setNewTicketModalOpen(false)}
      />
      <MainHeaderButtonsWrapper>

        <TextField
            style={{width:'20%'}}
            placeholder={'Pesquisar'}
            type="search"
            value={search}
            onChange={(e) => handleSearch(e.target.value, 'livre')}
            InputProps={{
              startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
              ),
            }}
        />
        <div   style={{width:'27%'}}>
          <TextField
              label={'Data criação'}
              style={{width:'47%'}}
              type="date"
              value={dateI}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleSearch(e.target.value, 'dateI')}
          />
          <TextField
              label={'Data previsão'}
              style={{width:'47%', marginLeft:10}}
              type="date"
              value={dateF}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleSearch(e.target.value, 'dateF')}
          />
        </div>
        {user.profile === 'user' && (
          <div   style={{width:'20%'}}>
            <Select
                style={{width: '100%'}}
                value={search2}
                onChange={(e) => handleSearch(e.target.value, 'contatos')}
            >
              <MenuItem value={'Todos'} >Contatos</MenuItem>
              {contacts && contacts.sort((a,b) => {
                if (a.toLowerCase() < b.toLowerCase()) {
                  return -1;
                }
                if (a.toLowerCase() > b.toLowerCase()) {
                  return 1;
                }
                return 0;
              }).map((c, i) =>(
                  <MenuItem key={i} value={c}>{c}</MenuItem>
              ))}

            </Select>
          </div>
        )}
        <div   style={{width:'15%'}}>
          <Select
              style={{width: '100%'}}
              value={search3}
              onChange={(e) => handleSearch(e.target.value, 'atendentes')}
          >
            <MenuItem value={'Todos'} >Atendentes</MenuItem>
            {users && users.map((c, i) =>(
                <MenuItem key={i} value={c.name}>{c.name}</MenuItem>
            ))}

          </Select>

        </div>
        <div   style={{width:'15%'}}>
            <Select
                style={{width: '100%'}}
                value={search1}
                onChange={(e) => handleSearch(e.target.value, 'status')}
            >
              <MenuItem value={'Todos'} >Status</MenuItem>
              <MenuItem value={'Nova'}>Nova</MenuItem>
              <MenuItem value={'Em andamento'}>Em andamento</MenuItem>
              <MenuItem value={'Vendido'}>Vendido</MenuItem>
              <MenuItem value={'Perdido'}>Perdido</MenuItem>
              <MenuItem value={'Pausado'}>Pausado</MenuItem>
              <MenuItem value={'Não'}>Não pausado</MenuItem>
            </Select>
        </div>
        {user.profile === 'admin' && (
            <Button
                variant="contained"
                color="primary"
                onClick={() => setModal(true)}
            >
              {'Adicionar coluna'}
            </Button>
        )}

      </MainHeaderButtonsWrapper>
      {lanes.length > 0 && (

          <>
            <ModalKanban
                open={modal}
                edit={editKanban}
                onSave={()=> onLaneAdd()}
                position={lanes.length}
                onClose={()=> {
                  setModal(false)
                  setEdit('')
                }}
            />
            <ModalTag
                tags={tags}
                colors={colors}
                open={modalTag}
                onSave={()=> onLaneAdd()}
                ticket={ticket}
                ticketFull={ticketfull}
                updateTags={(e) => updateTags(e)}
                addTags={(e) => addTags(e)}
                addStatus={(e) => addStatus(e)}
                onClose={()=> handleUpdate()}
            />
          </>

      )}

      {lanes.length > 0 &&
          (<Board
                collapsibleLanes
                style={{
                  backgroundColor: '#f4f4f4',
                  height:'83vh',

                }}
                canAddLanes={true}
                laneSortFunction={function noRefCheck(){}}
                data={{lanes:  data.lanes.sort((a,b) => {
                    if (a.position < b.position) {
                      return -1;
                    }
                    if (a.position > b.position) {
                      return 1;
                    }
                    return 0;
                  })}}
                lang={'en'}
                draggable
                handleDragEnd={handleDragEnd} //update card status
                hideCardDeleteIcon={true}
                editLaneTitle={false}
                disallowAddingCard
                editable={false}
                onCardClick={openTagsModal} //adicionar tags
                //onLaneAdd={onLaneAdd}
                //onLaneDelete={onLaneDelete}
                handleLaneDragEnd={handleLaneDragEnd} // atuliza a movimentacao da coluna
            />

      )}
    </div>
  );
};



export default Kanban;
