import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	paper: {
		height: 220,
		width: 230,
	},
	control: {
		padding: theme.spacing(2),
	},
}));


const EscalaModal = ({ open, onClose, queue }) => {
	const classes = useStyles();

	const [monday, setMonday] = useState();
	const [tuesday, setTuesday] = useState()
	const [wednesday, setWednesday] = useState()
	const [thursday, setThursday] = useState()
	const [friday, setFriday] = useState()
	const [saturday, setSaturday] = useState()
	const [sunday, setSunday] = useState()

	useEffect(() => {
		function setInfoQueue(){
			if (queue?.monday){
				setMonday(JSON.parse(queue.monday))
			}if (queue?.tuesday){
				setTuesday(JSON.parse(queue.tuesday))
			}if (queue?.wednesday){
				setWednesday(JSON.parse(queue.wednesday))
			}if (queue?.thursday){
				setThursday(JSON.parse(queue.thursday))
			}if (queue?.friday){
				setFriday(JSON.parse(queue.friday))
			}if (queue?.saturday){
				setSaturday(JSON.parse(queue.saturday))
			}if (queue?.sunday){
				setSunday(JSON.parse(queue.sunday))
			}
		}
		setInfoQueue();
	}, [queue, open, onClose]);

	const handleSaveWhatsApp = async () => {
		const whatsappData = {
				color: queue.color,
				name: queue.name,
				monday: monday ? JSON.stringify(monday) : '',
				tuesday: tuesday ? JSON.stringify(tuesday) : '',
				wednesday: wednesday ? JSON.stringify(wednesday) : '',
				thursday: thursday ? JSON.stringify(thursday) : '',
				friday: friday ? JSON.stringify(friday) : '',
				saturday: saturday ? JSON.stringify(saturday) : '',
				sunday: sunday ? JSON.stringify(sunday) : '',
		};

		try {
			await api.put(`/queue/${queue.id}`, whatsappData);
			toast.success('Departamento alterado com sucesso!');
			setTimeout(() => handleClose(),500);

		} catch (err) {
			toastError(err);
		}
	};

	const handleClose = () => {
		onClose()
		setMonday('');
		setTuesday('');
		setWednesday('');
		setThursday('');
		setFriday('');
		setSaturday('');
		setSunday('');
	};

	function handleTime(value, day, type){

		if (day === 'monday'){
			if (type === 'create' && value === true){
				setMonday({
					open: '08:00',
					close:'18:00'
				})
			}
			if (type === 'create' && value === false){
				setMonday('')
			}
			if (type === 'open'){
				setMonday({...monday, open: value})
			}if (type === 'close'){
				setMonday({...monday, close: value})
			}
		}

		if (day === 'tuesday'){
			if (type === 'create' && value === true){
				setTuesday({
					open: '08:00',
					close:'18:00'
				})
			}
			if (type === 'create' && value === false){
				setTuesday('')
			}
			if (type === 'open'){
				setTuesday({...tuesday, open: value})
			}if (type === 'close'){
				setTuesday({...tuesday, close: value})
			}
		}

		if (day === 'wednesday'){
			if (type === 'create' && value === true){
				setWednesday({
					open: '08:00',
					close:'18:00'
				})
			}
			if (type === 'create' && value === false){
				setWednesday('')
			}
			if (type === 'open'){
				setWednesday({...wednesday, open: value})
			}if (type === 'close'){
				setWednesday({...wednesday, close: value})
			}
		}

		if (day === 'thursday'){
			if (type === 'create' && value === true){
				setThursday({
					open: '08:00',
					close:'18:00'
				})
			}
			if (type === 'create' && value === false){
				setThursday('')
			}
			if (type === 'open'){
				setThursday({...thursday, open: value})
			}if (type === 'close'){
				setThursday({...thursday, close: value})
			}
		}

		if (day === 'friday'){
			if (type === 'create' && value === true){
				setFriday({
					open: '08:00',
					close:'18:00'
				})
			}
			if (type === 'create' && value === false){
				setFriday('')
			}
			if (type === 'open'){
				setFriday({...friday, open: value})
			}if (type === 'close'){
				setFriday({...friday, close: value})
			}
		}

		if (day === 'saturday'){
			if (type === 'create' && value === true){
				setSaturday({
					open: '08:00',
					close:'18:00'
				})
			}
			if (type === 'create' && value === false){
				setSaturday('')
			}
			if (type === 'open'){
				setSaturday({...saturday, open: value})
			}if (type === 'close'){
				setSaturday({...saturday, close: value})
			}
		}

		if (day === 'sunday'){
			if (type === 'create' && value === true){
				setSunday({
					open: '08:00',
					close:'18:00'
				})
			}
			if (type === 'create' && value === false){
				setSunday('')
			}
			if (type === 'open'){
				setSunday({...sunday, open: value})
			}if (type === 'close'){
				setSunday({...sunday, close: value})
			}
		}
	}

	const daysFull  = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'];
	const daysName  = ['Segunda-feira','Terça-feira','Quarta-feira','Quinta-feira','Sexta-feira','Sabádo','Domingo'];

	const verifyDay = (d) => {
		if (d === 'monday'){
			return monday?.open ? true : false
		}if (d === 'tuesday'){
			return tuesday?.open ? true : false
		}if (d === 'wednesday'){
			return wednesday?.open ? true : false
		}if (d === 'thursday'){
			return thursday?.open ? true : false
		}if (d === 'friday'){
			return friday?.open ? true : false
		}if (d === 'saturday'){
			return saturday?.open ? true : false
		}if (d === 'sunday'){
			return sunday?.open ? true : false
		}
	}

	const returnOpen = (d) => {
		if (d === 'monday'){
			return monday?.open ?? '';
		}if (d === 'tuesday'){
			return tuesday?.open ?? '';
		}if (d === 'wednesday'){
			return wednesday?.open ?? '';
		}if (d === 'thursday'){
			return thursday?.open ?? '';
		}if (d === 'friday'){
			return friday?.open ?? '';
		}if (d === 'saturday'){
			return saturday?.open ?? '';
		}if (d === 'sunday'){
			return sunday?.open ?? '';
		}
	}

	const returnClose = (d) => {
		if (d === 'monday'){
			return monday?.close ?? '';
		}if (d === 'tuesday'){
			return tuesday?.close ?? '';
		}if (d === 'wednesday'){
			return wednesday?.close ?? '';
		}if (d === 'thursday'){
			return thursday?.close ?? '';
		}if (d === 'friday'){
			return friday?.close ?? '';
		}if (d === 'saturday'){
			return saturday?.close ?? '';
		}if (d === 'sunday'){
			return sunday?.close ?? '';
		}
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				style={{width:'80%'}}
				fullScreen={true}
				maxWidth="lg"
				scroll="paper"
			>
				<DialogTitle>
					Escala de Atendimento
				</DialogTitle>

			<DialogContent dividers >
				<h3 style={{marginTop:0}}>Temporização</h3>
				<div style={{display:'flex', flexDirection:'row'}}>
					<Grid container className={classes.root} spacing={2}>
						<Grid item xs={12}>
							<Grid container justifyContent="flex-start" spacing={2}>

								{daysFull.map((d, i) => (

									<Grid item xs={3} key={d}>
										<Paper className={classes.paper}
											   style={{padding:20, display:'flex',alignItems:'center', justifyContent:'space-between',
												   flexDirection:'column'}}>
											<FormControlLabel
												control={
													<Switch
														value={1}
														checked={verifyDay(d)}
														onChange={(e) => handleTime(e.target.checked, d, 'create')}
														name="checkedB"
														color="primary"
													/>
												}
												label={daysName[i]}
											/>
											<TextField
												onChange={(e) => handleTime(e.target.value, d, 'open')}
												fullWidth={true}
												disabled={!verifyDay(d)}
												value={returnOpen(d)}
												label="Abertura"
												type="time"
												defaultValue="08:00"
												className={classes.textField}
												InputLabelProps={{
													shrink: true,
												}}
												inputProps={{
													step: 300, // 5 min
												}}
											/>
											<TextField
												onChange={(e) => handleTime(e.target.value, d, 'close')}
												fullWidth={true}
												disabled={!verifyDay(d)}
												value={returnClose(d)}
												label="Fechamento"
												type="time"
												defaultValue="18:00"
												className={classes.textField}
												InputLabelProps={{
													shrink: true,
												}}
												inputProps={{
													step: 300, // 5 min
												}}
											/>
										</Paper>
									</Grid>
								))}

							</Grid>
						</Grid>
					</Grid>

				</div>

			</DialogContent>


			<DialogActions>
				<Button
					onClick={handleClose}
					color="secondary"
					disabled={''}
					variant="outlined"
				>
					{i18n.t("whatsappModal.buttons.cancel")}
				</Button>
				<Button
					type="submit"
					onClick={handleSaveWhatsApp}
					color="primary"
					disabled={''}
					variant="contained"
					className={classes.btnWrapper}
				>
					Salvar
				</Button>
			</DialogActions>

			</Dialog>
		</div>
	);
};

export default React.memo(EscalaModal);
