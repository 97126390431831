import React, {useContext, useEffect, useState} from "react";
import Logo1 from "../../assets/logo.png";
// import Logo3 from "../../assets/logo3.png";
const Logo3 = require("../../assets/logo3.png");

const Logo = ({type}) => {
    const [img, setImg] = useState('');

    useEffect(() => {
        async function setLogo(){
            if (window.location.hostname === 'trademarketing.orgganize.com.br' || window.location.hostname === 'localhost'){
               setImg('trademarketing')
            }else{
                setImg('orgganizze');
            }
        }
        setLogo();
    }, []);

    return (
           <>
               {img === 'orgganizze' && type === 'login' && (
                   <img src={Logo1} alt="" width={250} />
               )}
               {img === 'trademarketing' && type === 'login' &&(
                   <img src={Logo3} alt="" width={250} />
               )}
               {img === 'orgganizze' && type === 'menu' && (
                   <img src={Logo1} alt="" height={35} />
               )}
               {img === 'trademarketing' && type === 'menu' &&(
                   <img src={Logo3} alt="" width={170} />
               )}
           </>
        )
}

export default Logo;