import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";

import { Dialog, DialogContent, Paper, Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

const QrcodeModal = ({ open, onClose, whatsAppId, link }) => {
	const [qrCode, setQrCode] = useState("");

	useEffect(() => {
		function config(){
			//document.getElementsByClassName('card-header').style.display = 'none'
		}
		config();
	}, [open]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth scroll="paper">
			<DialogContent>
				<Paper elevation={0}>
					<Typography color="primary" gutterBottom>
						{i18n.t("qrCode.message")}
					</Typography>
					<iframe src={link} frameborder="0" width={500} height={750}></iframe>
				</Paper>
			</DialogContent>
		</Dialog>
	);
};



export default React.memo(QrcodeModal);
