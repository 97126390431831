import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import useWhatsApps from "../../hooks/useWhatsApps";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
}));

const filterOptions = createFilterOptions({
	trim: true,
});

const TransferTicketModal = ({ modalOpen, onClose, ticketid, ticketWhatsappId }) => {
	const { user: loggedInUser } = useContext(AuthContext);

	const history = useHistory();
	const [options, setOptions] = useState([]);
	const [usersFull, setUsersfull] = useState([]);
	const [queues, setQueues] = useState([]);
	const [allQueuesFull, setAllQueuesFull] = useState([]);
	const [allQueues, setAllQueues] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedUser, setSelectedUser] = useState('fullUsers');
	const [selectedQueue, setSelectedQueue] = useState('');
	const [selectedWhatsapp, setSelectedWhatsapp] = useState(ticketWhatsappId);
	const classes = useStyles();
	const { findAll: findAllQueues } = useQueues(loggedInUser.company_id, ticketWhatsappId);
	const { loadingWhatsapps, whatsApps } = useWhatsApps();

	useEffect(() => {
		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalOpen]);
	useEffect(() => {
		fetchUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalOpen]);

	const loadQueues = async () => {
		const list = await findAllQueues();

		var l = [];
		list && list.length > 0 && list.map(ll => {
			l.push(ll.queue)
		})

		setAllQueuesFull(l);
		setAllQueues(l);
		setQueues(l);
	}

	const fetchUsers = async () => {
		const company_id = loggedInUser.company_id
		try {
			const { data } = await api.get("/users/", {
				params: { company_id:company_id },
			});
			setOptions(data.users);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedUser(null);
		setSelectedQueue(null);
	};

	const handleSetUsers = (queueId) => {
		setSelectedUser('fullUsers')
		setUsersfull([])
		setSelectedQueue(queueId)
			var result = options.filter(u => {
				return u.queues.find(all => {
					return queueId === all.id && u.id !== loggedInUser.id
				})
			})
		setUsersfull(result)
	};

	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!ticketid) return;
		setLoading(true);
		try {
			document.getElementById('ticket'+ticketid).style.display = 'none'
			let data = {};

			if (selectedUser && selectedUser !== 'fullUsers') {
				data.userId = selectedUser
			}
			data.status = 'pending';

			if (selectedQueue && selectedQueue !== null) {
				data.queueId = selectedQueue

				if (selectedUser === 'fullUsers') {
					data.status = 'pending';
					data.userId = null;
				}
			}

			if(selectedWhatsapp) {
				data.whatsappId = selectedWhatsapp;
			}

			await api.put(`/tickets/${ticketid}`, data);
			toast.success("Ticket transferido com sucesso!");
			setLoading(false);
			history.push(`/tickets`);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>

				<DialogContent dividers>
					<p style={{marginBottom:15, marginTop:0}}>A transferência de ticket pode ser para um atendente <br/> ou para um departamento sem vinculação! </p>

					<FormControl variant="outlined" className={classes.maxWidth}>
						<InputLabel>Escolher Departamento</InputLabel>
						<Select
							value={selectedQueue}
							onChange={(e) => handleSetUsers(e.target.value) }
							label={'Transferir departamento'}
							required={true}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl style={{marginTop:19}} variant="outlined" className={classes.maxWidth}>
						<InputLabel>Escolher Atendente</InputLabel>
						<Select
							value={selectedUser}
							onChange={e => setSelectedUser(e.target.value)}
							label={'Escolher Atendente'}
							required={true}
						>
							{usersFull && usersFull.length > 0 ? (
									<MenuItem value={'fullUsers'} selected={true}>Todos atendentes</MenuItem>
								)
								: (
									<MenuItem value={'fullUsers'} selected={true}>Nenhum atendente</MenuItem>
								)}

							{usersFull && usersFull.map((u) => (
								<MenuItem key={u.id} value={u.id}>{u.name}</MenuItem>
							))}
						</Select>
					</FormControl>

					<Can

						role={loggedInUser.profile}
						perform="ticket-options:transferWhatsapp"
						yes={() => (!loadingWhatsapps && 
							<FormControl  variant="outlined" className={classes.maxWidth} style={{ marginTop: 20, display:'none' }}>
								<InputLabel>{i18n.t("transferTicketModal.fieldConnectionLabel")}</InputLabel>
								<Select
									value={selectedWhatsapp}
									onChange={(e) => setSelectedWhatsapp(e.target.value)}
									label={i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
								>
									{whatsApps.map((whasapp) => (
										<MenuItem key={whasapp.id} value={whasapp.id}>{whasapp.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						{i18n.t("transferTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TransferTicketModal;
