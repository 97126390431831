import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {toast} from "react-toastify";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const NewScheduleModal = ({ modalOpen, onClose, ticket  }) => {
	const history = useHistory();
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [description, setDescription] = useState('');
	const [start, setStart] = useState('');

	const { user } = useContext(AuthContext);



	const handleClose = () => {
		onClose();
	};

	const handleSaveTicket = async () => {
		if (!description || !start){
			return toast.warning('Falta informações para preencher')
		}

		const event = {
			user_id: user.id,
			warn: 1,
			description: description,
			start: start,
			end: start,
			title: 'Msg agendada para '+ticket.contact.name+' - '+ticket.contact.number,
			ticket_id: ticket.id ?? '',
			company_id:user.company_id ?? '',
			whatsapp_id:ticket.whatsappId ?? '',
			contact_number:ticket.contact.number ?? '',
		}

		setLoading(true);
		try {

			const { data } = await api.post('events', event)

			toast.success('Mensagem agendada com sucesso!')
			history.push(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
		setLoading(false);
		handleClose();
	};


	return (
		<>

			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					Agendar o envio de mensagem para {ticket.contact.name ?? ''}
				</DialogTitle>
				<DialogContent dividers>

					<form className={classes.root} noValidate autoComplete="off">
						<TextField
							id="datetime-local"
							label="Data e hora do envio"
							type="datetime-local"
							className={classes.textField}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={(e) => setStart(e.target.value)}
						/>
						<br/>
						<br/>
						<TextField
							style={{width:'100%', marginTop:20}}
							id="outlined-multiline-static"
							label="Mensagem a ser enviada"
							multiline
							rows={5}
							variant="outlined"
							onChange={(e) => setDescription(e.target.value)}
						/>
					</form>

				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={!ticket}
						onClick={() => handleSaveTicket()}
						color="primary"
						loading={loading}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewScheduleModal;
