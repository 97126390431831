import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Info from '@material-ui/icons/Info';


import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {Picker} from "emoji-mart";
import MoodIcon from "@material-ui/icons/Mood";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));


const ParametrosModal = ({ open, onClose, whatsAppId }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseP = () => {
		setAnchorEl(null);
	};

	const initialState = {
		name: "",
		greetingMessage: "",
		farewellMessage: "",
		isDefault: false,
	};
	const [whatsApp, setWhatsApp] = useState(initialState);
	const [msg_initial, setInitial] = useState('');
	const [msg_finish, setFinish] = useState('');
	const [msg_contact_pause, setMsgcontactPause] = useState('');
	const [msg_user_pause, setMsguserPause] = useState('');
	const [msg_time, setMsgTime] = useState('');


	const [showEmoji, setShowEmoji] = useState(false);
	const [showEmoji1, setShowEmoji1] = useState(false);
	const [showEmoji2, setShowEmoji2] = useState(false);
	const [showEmoji3, setShowEmoji3] = useState(false);
	const [showEmoji5, setShowEmoji5] = useState(false);

	const [openP, setOpenp] = useState(false);
	const [parametros, setParametros] = useState({
		attendant: 0,
		contact:0,
		ticket:0,
	});
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsappId/${whatsAppId}`);
				setWhatsApp(data);

				setMsgTime(data.msg_time)
				setMsgcontactPause(data.msg_contact_pause)
				setMsguserPause(data.msg_user_pause)
				setFinish(data.msg_finish)
				setInitial(data.msg_initial)

				if (data.parameters){
					setParametros(JSON.parse(data.parameters))

				}else{
					setParametros({
						attendant: 0,
						contact:0,
						ticket:0,
					})
				}
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	const handleSaveWhatsApp = async () => {
		const whatsappData = {
			parameters: JSON.stringify(parametros),
			msg_initial,
			msg_finish,
			msg_contact_pause,
			msg_user_pause,
			msg_time,
		};

		try {
			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
			} else {
				await api.post("/whatsapp", whatsappData);
			}
			toast.success(i18n.t("whatsappModal.success"));
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
	};

	const handleSaveParams = (value, type) => {
		if (value >= 0){
			if (type === 'attendant'){
				setParametros({...parametros, attendant: value})
			}
			if (type === 'contact'){
				setParametros({...parametros, contact: value})
			}
			if (type === 'ticket'){
				setParametros({...parametros, ticket: value})
			}
		}
	};

	const handleAddEmoji = (e, type) => {
		let emoji = e.native;
		if (type === 'msg_initial'){
			setInitial((prevState) => prevState + emoji);
		}if (type === 'msg_contact_pause'){
			setMsgcontactPause((prevState) => prevState + emoji);
		}if (type === 'msg_finish'){
			setFinish((prevState) => prevState + emoji);
		}if (type === 'msg_user_pause'){
			setMsguserPause((prevState) => prevState + emoji);
		}if (type === 'msg_time'){
			setMsgTime((prevState) => prevState + emoji);
		}
	};


	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				style={{width:'80%'}}
				fullScreen={true}
				maxWidth="lg"
				scroll="paper"
			>
				<DialogTitle>
					{whatsAppId
						? "Paramêtros "+whatsApp.name
						: ''}
				</DialogTitle>

			<DialogContent dividers >
				<h3 style={{marginTop:0}}>Temporização</h3>
				<div className={classes.multFieldLine}>
							<div style={{display:'flex', flexDirection:'row'}}>

								<TextField
									label={ <span>Tempo Ocioso (Atendente)</span>}
									defaultValue="0"
									helperText="Minutos"
									variant="outlined"
									value={parametros.attendant}
									type={'number'}
									InputLabelProps={{
										"aria-valuemin": 0,
									}}
									style={{marginRight:5}}
									onChange={(e) => handleSaveParams(e.target.value, 'attendant')}
								/>
								<Tooltip title="Defina o tempo máximo que os seus atendentes devem responder um chamado. Um alerta será exibido ao atendente dentro do prazo que você estipular abaixo para que ele dê continuidade ao atendimento em aberto." >
									<Info style={{marginTop:-15, marginLeft:-20}}/>
								</Tooltip>

							</div>
							<div style={{display:'flex', flexDirection:'row'}}>
								<TextField
									label="Tempo Ocioso (Contato)"
									defaultValue="0"
									helperText="Minutos"
									variant="outlined"
									value={parametros.contact}
									type={'number'}
									InputLabelProps={{
										"aria-valuemin": 0,
									}}
									onChange={(e) => handleSaveParams(e.target.value, 'contact')}
								/>
								<Tooltip title="Defina um prazo limite de espara por uma interação do seu lead. Uma mensagem automática será enviada e o ticket será dado como encerrado automaticamente quando atingir o perído informado abaixo." >
									<Info style={{marginTop:-15, marginLeft:-20}}/>
								</Tooltip>
							</div>
							<div style={{display:'flex', flexDirection:'row'}}>
								<TextField
									label="Tempo Reabertura Ticket"
									defaultValue="0"
									helperText="Minutos"
									variant="outlined"
									value={parametros.ticket}
									type={'number'}
									InputLabelProps={{
										"aria-valuemin": 0,
									}}
									onChange={(e) => handleSaveParams(e.target.value, 'ticket')}
								/>
								<Tooltip title="Defina uma janela de tempo que o sistema irá ignorar novas mensagens do lead após o encerramento de um ticket. Informe abaixo quanto tempo." >
									<Info style={{marginTop:-15, marginLeft:-20}}/>
								</Tooltip>
							</div>
				</div>

				<h3>Mensagens Automáticas 	<Tooltip title="Programe mensagens automáticas que serão enviadas de acordo com o estágio do atendimento." >
					<Info/>
				</Tooltip>
				</h3>

				<div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-between'}}>

					<div style={{display:'flex', flexDirection:'column', width:'48%', alignItems:'flex-end'}}>
						<div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'start'}}>
							<TextField
								fullWidth={'50%'}
								label={'Boas-vindas'}
								minRows={5}
								variant="outlined"
								value={msg_initial}
								multiline={true}
								style={{marginRight:5}}
								onChange={(e) => setInitial(e.target.value)}
							/>
							<Tooltip title="Esse texto será enviado sempre que seu canal receber a primeira mensagem de um lead." >
								<Info style={{marginTop:-15, marginLeft:-20}}/>
							</Tooltip>
						</div>
						<IconButton
							style={{position: 'relative'}}
							aria-label="emojiPicker"
							component="span"
							onClick={(e) => setShowEmoji((prevState) => !prevState)}
						>
							<MoodIcon className={classes.sendMessageIcons} />
						</IconButton>
						{showEmoji && (
							<div className={classes.emojiBox}>
								<ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
									<Picker
										perLine={8}
										showPreview={false}
										showSkinTones={false}
										onSelect={(e) => handleAddEmoji(e, 'msg_initial')}
									/>
								</ClickAwayListener>
							</div>
						)}

					</div>
					<div style={{display:'flex', flexDirection:'column', width:'48%', alignItems:'flex-end'}}>
						<div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'start'}}>
							<TextField
								fullWidth={'50%'}
								label={'Finalização'}
								minRows={5}
								variant="outlined"
								value={msg_finish}
								multiline={true}
								style={{marginRight:5}}
								onChange={(e) => setFinish(e.target.value)}
							/>
							<Tooltip title="Mensagem que será enviada sempre que um ticket for finalizado." >
								<Info style={{marginTop:-15, marginLeft:-20}}/>
							</Tooltip>
						</div>
						<IconButton
							style={{position: 'relative'}}
							aria-label="emojiPicker"
							component="span"
							onClick={(e) => setShowEmoji2((prevState) => !prevState)}
						>
							<MoodIcon className={classes.sendMessageIcons} />
						</IconButton>
						{showEmoji2 && (
							<div className={classes.emojiBox}>
								<ClickAwayListener onClickAway={(e) => setShowEmoji2(false)}>
									<Picker
										perLine={8}
										showPreview={false}
										showSkinTones={false}
										onSelect={(e) => handleAddEmoji(e, 'msg_finish')}
									/>
								</ClickAwayListener>
							</div>
						)}

					</div>

				</div>
				<div style={{display:'flex',marginTop:30, flexDirection:'row', width:'100%', justifyContent:'space-between'}}>

					<div style={{display:'flex', flexDirection:'column', width:'48%', alignItems:'flex-end'}}>
						<div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'start'}}>
							<TextField
								fullWidth={'50%'}
								label={'Atendente ocioso'}
								minRows={5}
								variant="outlined"
								value={msg_user_pause}
								multiline={true}
								style={{marginRight:5}}
								onChange={(e) => setMsguserPause(e.target.value)}
							/>
							<Tooltip title="Esse texto será enviado sempre que seu canal receber a primeira mensagem de um lead." >
								<Info style={{marginTop:-15, marginLeft:-20}}/>
							</Tooltip>
						</div>
						<IconButton
							style={{position: 'relative'}}
							aria-label="emojiPicker"
							component="span"
							onClick={(e) => setShowEmoji3((prevState) => !prevState)}
						>
							<MoodIcon className={classes.sendMessageIcons} />
						</IconButton>
						{showEmoji3 && (
							<div className={classes.emojiBox}>
								<ClickAwayListener onClickAway={(e) => setShowEmoji3(false)}>
									<Picker
										perLine={8}
										showPreview={false}
										showSkinTones={false}
										onSelect={(e) => handleAddEmoji(e, 'msg_user_pause')}
									/>
								</ClickAwayListener>
							</div>
						)}

					</div>
					<div style={{display:'flex', flexDirection:'column', width:'48%', alignItems:'flex-end'}}>
						<div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'start'}}>
							<TextField
								fullWidth={'50%'}
								label={'Contato ocioso'}
								minRows={5}
								variant="outlined"
								value={msg_contact_pause}
								multiline={true}
								style={{marginRight:5}}
								onChange={(e) => setMsgcontactPause(e.target.value)}
							/>
							<Tooltip title="Essa mensagem será exibida para o seu lead quando ele deixar de interagir com o canal respeitando o prazo que você determinou na temporização de 'Tempo Ocioso (Lead)'" >
								<Info style={{marginTop:-15, marginLeft:-20}}/>
							</Tooltip>
						</div>
						<IconButton
							style={{position: 'relative'}}
							aria-label="emojiPicker"
							component="span"
							onClick={(e) => setShowEmoji1((prevState) => !prevState)}
						>
							<MoodIcon className={classes.sendMessageIcons} />
						</IconButton>
						{showEmoji1 && (
							<div className={classes.emojiBox}>
								<ClickAwayListener onClickAway={(e) => setShowEmoji1(false)}>
									<Picker
										perLine={8}
										showPreview={false}
										showSkinTones={false}
										onSelect={(e) => handleAddEmoji(e, 'msg_contact_pause')}
									/>
								</ClickAwayListener>
							</div>
						)}

					</div>

				</div>
				<div style={{display:'flex', flexDirection:'column', width:'48%', alignItems:'flex-end'}}>
					<div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'start'}}>
						<TextField
							fullWidth={'50%'}
							label={'Fora da Escala de Horário'}
							minRows={5}
							variant="outlined"
							value={msg_time}
							multiline={true}
							style={{marginRight:5}}
							onChange={(e) => setMsgTime(e.target.value)}
						/>
						<Tooltip title="Essa mensagem será enviada para os leads que interagirem com o canal fora do horário de atendimento informado na 'ESCALA DE ATENDIMENTO' de cada departamento." >
							<Info style={{marginTop:-15, marginLeft:-20}}/>
						</Tooltip>
					</div>
					<IconButton
						style={{position: 'relative'}}
						aria-label="emojiPicker"
						component="span"
						onClick={(e) => setShowEmoji5((prevState) => !prevState)}
					>
						<MoodIcon className={classes.sendMessageIcons} />
					</IconButton>
					{showEmoji5 && (
						<div className={classes.emojiBox}>
							<ClickAwayListener onClickAway={(e) => setShowEmoji5(false)}>
								<Picker
									perLine={8}
									showPreview={false}
									showSkinTones={false}
									onSelect={(e) => handleAddEmoji(e, 'msg_time')}
								/>
							</ClickAwayListener>
						</div>
					)}

				</div>

			</DialogContent>


			<DialogActions>
				<p>
					Dicas: Utilize a variável <span style={{color:'tomato'}}>%NAME%</span> para exibir o nome do lead na mensagem. Exiba palavras em negrito utilizando *antes e depois do texto desejado. Por exemplo, digite: *Texto*  Resultado: <b>Texto</b>
				</p>
				<Button
					onClick={handleClose}
					color="secondary"
					disabled={''}
					variant="outlined"
				>
					{i18n.t("whatsappModal.buttons.cancel")}
				</Button>
				<Button
					type="submit"
					onClick={handleSaveWhatsApp}
					color="primary"
					disabled={''}
					variant="contained"
					className={classes.btnWrapper}
				>
					Salvar
				</Button>
			</DialogActions>

			</Dialog>
		</div>
	);
};

export default React.memo(ParametrosModal);
