import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	Label,
	ResponsiveContainer,
} from "recharts";
import {startOfHour, parseISO, format, startOfDay} from "date-fns";

import { i18n } from "../../translate/i18n";

import Title from "./Title";
import useTickets from "../../hooks/useTickets";

const Chart = () => {
	const theme = useTheme();

	const date = useRef(new Date().toISOString());
	const { tickets } = useTickets({ date: date.current });
	const day = new Date();
	var dateEnd = new Date(day.setMonth(day.getMonth() - 1));



	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		async function setDatas(){
			var res = []
			for(let i=1;i<32;i++){
				const dateView = new Date(dateEnd.setHours(dateEnd.getHours() + 24));
				if (i > 8){
					var totalAmount=0
					tickets.forEach(ticket => {
						const dd = new Date(ticket.createdAt)
						const dv = new Intl.DateTimeFormat('pt-BR').format(dd);
						const d1 = new Intl.DateTimeFormat('pt-BR').format(dateView);
						if (dv === d1){
							totalAmount++
						}
					});
					res.push({
						time: new Intl.DateTimeFormat('pt-BR').format(dateView), amount: totalAmount
					})
				}

			}

		/*	var result = loggedInUser.queues.filter(q => {
				return l.find(all => {
					return q.id === all.id
				})
			})*/



			setChartData(res)
		}
		setDatas();
	}, [tickets]);

	/*useEffect(() => {
		setChartData(prevState => {
			let aux = [...prevState];

			aux.forEach(a => {
				tickets.forEach(ticket => {
					format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time &&
						a.amount++;
				});
			});

			return aux;
		});
	}, [tickets]);*/

	return (
		<React.Fragment>
			<Title style={{ color:'#4d4d4d'}}>
				Tickets ultimos 20 dias
			</Title>
			<ResponsiveContainer>
				<BarChart
					data={chartData}
					barSize={30}
					width={730}
					height={350}
					margin={{
						top: 16,
						right: 16,
						bottom: 0,
						left: 24,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="time" stroke={theme.palette.text.secondary} />
					<YAxis
						type="number"
						allowDecimals={false}
						stroke={theme.palette.text.secondary}
					>
						<Label
							angle={270}
							position="left"
							style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
						>
							Qtd tickets
						</Label>
					</YAxis>
					<Bar dataKey="amount" fill={theme.palette.primary.main} />
				</BarChart>
			</ResponsiveContainer>
		</React.Fragment>
	);
};

export default Chart;
