import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import useWhatsApps from "../../hooks/useWhatsApps";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Can} from "../Can";
import QueueSelect from "../QueueSelect";
import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {toast} from "react-toastify";

const filter = createFilterOptions({
	trim: true,
});

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const NewTicketModal = ({ modalOpen, onClose, contactFull = null }) => {
	const history = useHistory();
	const classes = useStyles();

	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [whatsappId, setWhatsappId] = useState(false);
	const {loadingWhats, whatsApps} = useWhatsApps();

	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState('');
	const [selectedContact, setSelectedContact] = useState('');
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const { user } = useContext(AuthContext);


	useEffect(() => {
		if (contactFull && contactFull.name){
			setSelectedContact(contactFull.name)
		}
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam, company_id:user.company_id },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
	};

	const handleSaveTicket = async (contactId) => {

		if (contactFull){
			contactId = contactFull.id
		}

		if (!contactId) return;
		setLoading(true);
		try {

			const { data } = await api.post("/ticketsNew", {
				contactId: contactId,
				userId: user.id,
				status: "open",
				company_id: user.company_id,
				whatsappId,
				queueId:selectedQueueIds[0]
			});

			if (data.mensagem){
				setLoading(false)
				setSearchParam('')
				setSelectedContact('')
				return toast.error(data.mensagem)
			}

			const type = window.location.pathname;

			if (type === '/kanban'){
				window.location.reload();
			}else{
				history.push(`/tickets/${data.id}`);
			}
		} catch (err) {
			toastError(err);
		}
		setLoading(false);
		handleClose();
	};

	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
		} else if (newValue?.name) {
			setNewContact({ name: newValue.name });
			setContactModalOpen(true);
		}
	};

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleAddNewContactTicket = contact => {
		handleSaveTicket(contact.id);
	};

	const createAddContactOption = (filterOptions, params) => {
		const filtered = filter(filterOptions, params);

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filtered.push({
				name: `${params.inputValue}`,
			});
		}

		return filtered;
	};

	const renderOption = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${i18n.t("newTicketModal.add")} ${option.name}`;
		}
	};

	const renderOptionLabel = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${option.name}`;
		}
	};

	return (
		<>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
			></ContactModal>
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("newTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<Autocomplete
						options={options}
						loading={loading}
						style={{ width: 300 }}
						clearOnBlur
						disabled={contactFull && contactFull.id}
						autoHighlight
						freeSolo
						clearOnEscape
						getOptionLabel={renderOptionLabel}
						renderOption={renderOption}
						filterOptions={createAddContactOption}
						onChange={(e, newValue) => handleSelectOption(e, newValue)}
						renderInput={params => (
							<TextField
								{...params}
								label={(contactFull && contactFull.name) ? (`${contactFull.name} - ${contactFull.number}`) : i18n.t("newTicketModal.fieldLabel")}
								variant="outlined"
								value={(contactFull && contactFull.name) ? (`${contactFull.name} - ${contactFull.number}`) : ''}
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								onKeyPress={e => {
									if (loading || !selectedContact) return;
									else if (e.key === "Enter") {
										handleSaveTicket(selectedContact.id);
									}
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
					<br/>
					<Can
						role={'admin'}
						perform="user-modal:editQueues"
						yes={() => (!loadingWhats &&
							<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
								<InputLabel>Canais</InputLabel>
								<Select
									labelWidth={10}
									value={whatsappId}
									onChange={(e) => setWhatsappId(e.target.value)}
									label={i18n.t("userModal.form.whatsapp")}
								>
									<MenuItem value={''}>&nbsp;</MenuItem>
									{whatsApps.map((whatsapp) => (
										<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name} - {whatsapp.phone}</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					/>
					<Can
						role={'admin'}
						perform="user-modal:editQueues"
						yes={() => (
							<QueueSelect
								type={true}
								whatsappId={whatsappId}
								selectedQueueIds={selectedQueueIds}
								onChange={values => setSelectedQueueIds(values)}
							/>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={!selectedContact || !whatsappId || !selectedQueueIds[0]}
						onClick={() => handleSaveTicket(selectedContact.id)}
						color="primary"
						loading={loading}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewTicketModal;
