import React, {useState, useEffect, useRef, useContext} from "react";

import * as Yup from "yup";
import { Formik,  Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import {Grid,} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { i18n } from "../../translate/i18n";
import  KanbanColors from './kanbanColors'
import api from "../../services/api";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ContactDrawerKanban from "../../components/ContactDrawerKanban";
import {AuthContext} from "../../context/Auth/AuthContext";
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ContactSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, "Muito pequeno!")
        .required("Campo obrigatório!"),

});

const KanbanModalTags = ({ open, onClose, initialValues, onSave, ticket, tags, updateTags, addTags, colors, addStatus, ticketFull }) => {
    const classes = useStyles();
    const [openModalColor, setModalcolor] = useState('')
    const [statusKanban, setStatuskanban] = useState('')
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tagsid, setTagsid] = useState([]);
    const [colornew, setColornew] = useState(colors.length > 0 ? colors[0].color : '#312F52');
    const isMounted = useRef(true);
    const [value, setValue] = React.useState('1');
    const { user } = useContext(AuthContext);

    const initialState = {
        title: "",
        color: "#ffffff",
        bgcolor: "#312F52",
        ticket_id: '',
    };

    const [contact, setContact] = useState(initialState);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (tags){
            const resuls = [];
            const result = tags.map((t, i) => {
                t.filter(tt => {
                    if (parseInt(ticket) === parseInt(tt.ticket_id)){
                        resuls.push(tt)
                    }
                })
            })
            setTagsid(resuls)
        }
    }, [tags, ticket]);

    useEffect(() => {
        async function getTicket(){
            const res = await api.get(`/tickets/${ticket}`)
            setStatuskanban(res.data.status_kanban ?? 'Sem status')
        }
        getTicket()
    }, [ticket]);


    const handleClose = () => {
        onClose();
        setContact(initialState);
    };

    const deleteTag = async (id) => {
        await api.delete("/removeTag/"+id);
        const t = tagsid.filter(t => {
            return t.id !== id;
        })
        setTagsid(t)
        updateTags(id)
        toast.success('Tag deletada com sucesso!')
    }

    const handleSave = async values => {
        setContact(initialState)
        values.ticket_id = ticket;
        values.bgcolor = colornew;
        const { data } = await api.post("/kanbansTags", values);
        const resultaTags = tagsid;
        resultaTags.push(data)

        addTags(data)
        toast.success('Tag adicionada com sucesso!')
        setTagsid(resultaTags)
        onClose()
        //window.location.reload();
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleStatusKanban = async (value) => {
        setStatuskanban(value);

        const t = {
            status_kanban: value
        }
        toast.success('Status alterado com sucesso!')
        const { data } = await api.post("/ticketStatus/"+ticket, t);
        addStatus(data)
    }


    const deleteColors = async (id, indice) => {
        document.getElementById('color'+id).style.display = 'none'
        await api.delete("/deleteColor/"+id);
        colors.splice(indice, 1);
        toast.success('Cor deletada com sucesso!')
    }

    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={true}
                maxWidth="lg"
                style={{width:'100%',maxWidth:700, overflow:'auto'}}
                scroll="paper"
                open={open} onClose={handleClose} >
                <Grid container>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange}  indicatorColor="primary"
                                         textColor="primary" aria-label="lab API tabs example">
                                    <Tab label="Contato" value="1" />
                                    <Tab label="Tags" value="2" />
                                    <Tab label="Outras configurações" value="3" />
                                </TabList>
                            </Box>


                            <TabPanel value="1">

                                <ContactDrawerKanban
                                    statusKanban={user.profile === 'admin' ? ticketFull.kanban_admin : ticketFull.kanban_user}
                                    ticketId={ticketFull.id}
                                    ticket={ticketFull}
                                    origemTicket={ticketFull.origem}
                                    moneyTicket={ticketFull.value}
                                    contact={ticketFull.contact}
                                />

                            </TabPanel>

                            <TabPanel value="2">

                                <DialogTitle id="form-dialog-title">
                                    Adicionar tag
                                </DialogTitle>
                                <Formik
                                    initialValues={contact}
                                    enableReinitialize={true}
                                    validationSchema={ContactSchema}
                                    onSubmit={(values, actions) => {
                                        setTimeout(() => {
                                            handleSave(values);
                                            actions.setSubmitting(false);
                                        }, 400);
                                    }}
                                >
                                    {({ values, errors, touched, isSubmitting }) => (
                                        <Form>
                                            <DialogContent dividers>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Titulo
                                                </Typography>
                                                <Field
                                                    as={TextField}
                                                    label={''}
                                                    name="title"
                                                    autoFocus
                                                    error={touched.title && Boolean(errors.title)}
                                                    helperText={touched.title && errors.title}
                                                    variant="outlined"
                                                    margin="dense"
                                                    className={classes.textField}
                                                />
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Cores
                                                </Typography>
                                                <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                    {colors.length > 0 ? colors.map((c, indice) => (
                                                        <div id={'color'+c.id} key={c.id} onClick={() => setColornew(c.color)}
                                                             style={{cursor:"pointer",marginBottom:15, marginLeft:15, width:27,height:27,border:c.color === colornew ? '3px solid #414350' : 'none' ,borderRadius:20, backgroundColor: c.color}}>
                                                            <HighlightOffIcon
                                                                onClick={() => deleteColors(c.id, indice)}
                                                                style={{
                                                                    fontSize:20,
                                                                    marginLeft:13,
                                                                    marginTop:-13,
                                                                    position:'absolute',
                                                                    cursor:'pointer',
                                                                    backgroundColor:'#fff',
                                                                    borderRadius:50,
                                                                    color:'black !important'}}
                                                            />
                                                        </div>
                                                    )) :  (
                                                        <div onClick={() => setColornew('#312F52')}
                                                             style={{cursor:"pointer",marginBottom:15, marginLeft:15, width:27,height:27,border: '#312F52' === colornew ? '3px solid blue' : 'none' ,borderRadius:20, backgroundColor:'#312F52'}}>
                                                        </div>
                                                    )}
                                                </div>
                                                <Button variant="contained" style={{marginTop:20, marginBottom:20}} size="small" onClick={()=>setModalcolor(true)}>
                                                    Adicionar mais cores
                                                </Button>


                                                <KanbanColors
                                                    colors={(color) => colors.push(color)}
                                                    open={openModalColor}
                                                    setColorNew={(c) => setColornew(c)}
                                                    onClose={()=> setModalcolor(false)}
                                                />
                                                <Field
                                                    type={'hidden'}
                                                    label={''}
                                                    value={colornew}
                                                    name="bgcolor"
                                                    autoFocus
                                                    variant="outlined"
                                                    margin="dense"
                                                    className={classes.textField}
                                                />
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Cor do texto
                                                </Typography>
                                                <Field
                                                    type={'color'}
                                                    label={''}
                                                    name="color"
                                                    autoFocus
                                                    variant="outlined"
                                                    margin="dense"
                                                    className={classes.textField}
                                                />
                                                <Field
                                                    type={'hidden'}
                                                    value={ticket}
                                                    name="ticket_id"
                                                />

                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={handleClose}
                                                    color="default"
                                                    disabled={isSubmitting}
                                                    variant="outlined"
                                                >
                                                    {i18n.t("contactModal.buttons.cancel")}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    variant="contained"
                                                    className={classes.btnWrapper}
                                                >
                                                    Salvar
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    )}
                                </Formik>
                                <DialogTitle id="form-dialog-title">
                                    Tags cadastradas
                                </DialogTitle>
                                {tagsid.length > 0 && (
                                    <div style={{display:'flex',flex:1, flexWrap:'wrap', width:'100%'}}>
                                        {tagsid.map((t, indice) => (
                                            <span id={t.id}  style={{
                                                margin:3}}>
                                          <span style={{padding:7,
                                              borderRadius:5,
                                              width: 'auto',
                                              height:30,
                                              backgroundColor: t.bgcolor,
                                              color:t.color
                                          }}>{t.title}

                                    </span>
                                        <HighlightOffIcon
                                            onClick={() => deleteTag(t.id)}
                                            style={{
                                                marginLeft:-10,
                                                marginBottom:7,
                                                cursor:'pointer',
                                                backgroundColor:'#fff',
                                                borderRadius:50,
                                                color:'black !important'}}
                                        />
                                    </span>

                                        ))}
                                    </div>
                                )}


                            </TabPanel>

                            <TabPanel value="3">

                                <div style={{padding:20}}>
                                    <InputLabel style={{marginBottom:10}}>Status da Negociação</InputLabel>
                                    <FormControl variant="outlined" className={classes.maxWidth} style={{width:'85%'}}>

                                        <Select
                                            value={statusKanban}
                                            onChange={(e) => handleStatusKanban(e.target.value)}
                                        >
                                            <MenuItem value={'Em andamento'}>Em andamento</MenuItem>
                                            <MenuItem value={'Vendido'}>Vendido</MenuItem>
                                            <MenuItem value={'Perdido'}>Perdido</MenuItem>
                                            <MenuItem value={'Pausado'}>Pausado</MenuItem>
                                            <MenuItem value={'Não pausado'}>Não pausado</MenuItem>

                                        </Select>

                                    </FormControl>
                                </div>

                            </TabPanel>
                        </TabContext>
                    </Box>



                </Grid>



            </Dialog>
        </div>
    );
};

export default KanbanModalTags;
