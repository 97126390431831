import React, {useContext, useEffect, useState} from "react"

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import useTickets from "../../hooks/useTickets"

import { AuthContext } from "../../context/Auth/AuthContext";
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import Timer from '@material-ui/icons/Timer';
import ChatIcon from '@material-ui/icons/Chat';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import Title from "./Title";
import { i18n } from "../../translate/i18n";

import Chart from "./Chart"
import Chart2 from "./Chart2"
import TableCell from "@material-ui/core/TableCell";
import Avatar from "@material-ui/core/Avatar";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {Tooltip} from "@material-ui/core";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
}))

const Dashboard = () => {
	const classes = useStyles()
	const[ticket, setTicket] = useState([]);
	const[tma, setTma] = useState(0);
	const[tme, setTme] = useState(0);

	const { user } = useContext(AuthContext);
	var userQueueIds = [];

	if (user.queues && user.queues.length > 0) {
		userQueueIds = user.queues.map(q => q.id);
	}

	useEffect(() => {
		async function tmaTme(){
			const { data } = await api.get("/tmaTme/"+user?.company_id);
			setTma(data.tma)
			setTme(data.tme)
		}
		tmaTme()
	}, []);

	const GetTickets = (status, showAll, withUnreadMessages) => {

		const { count } = useTickets({
			status: status,
			showAll: showAll,
			withUnreadMessages: withUnreadMessages,
			queueIds: JSON.stringify(userQueueIds)
		});

		return count;
	}

	const GetTicketsFull = (status, showAll, withUnreadMessages) => {

		const { count, tickets } = useTickets({
			status: status,
			showAll: showAll,
			withUnreadMessages: withUnreadMessages,
			queueIds: JSON.stringify(userQueueIds)
		});

		return tickets;
	}

	function openTicket(id){
		window.location.href = '/tickets/'+id
	}

	return (
		<div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="p" variant="p"  paragraph style={{fontSize:15}}>
								{i18n.t("dashboard.messages.inAttendance.title")}
							</Typography>
							<div style={{display:'flex', color:'#4d4d4d',alignItems:'center',flexDirection:'row', justifyContent:'space-between'}}>
								<Typography component="p" variant="h6" >
									{GetTickets("open", "true", "false")} tickets
								</Typography>
								<PhoneInTalkIcon />
							</div>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="p" variant="p"  paragraph style={{fontSize:15}}>
								{i18n.t("dashboard.messages.closed.title")}s
							</Typography>
							<div style={{display:'flex', color:'#4d4d4d',alignItems:'center',flexDirection:'row', justifyContent:'space-between'}}>
								<Typography component="p" variant="h6" >
									{GetTickets("closed", "true", "false")} tickets
								</Typography>
								<AssignmentTurnedInIcon />
							</div>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="p" variant="p"  paragraph style={{fontSize:15}}>
								{i18n.t("dashboard.messages.waiting.title")}
							</Typography>
							<div style={{display:'flex', color:'#4d4d4d',alignItems:'center',flexDirection:'row', justifyContent:'space-between'}}>
								<Typography component="p" variant="h6" >
									{GetTickets("pending", "true", "false")} tickets
								</Typography>
								<AnnouncementIcon />
							</div>
						</Paper>
					</Grid>


					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="p" variant="p"  paragraph style={{fontSize:15}}>
								TME
							</Typography>
							<div style={{display:'flex', color:'#4d4d4d',alignItems:'center',flexDirection:'row', justifyContent:'space-between'}}>
								<Typography component="p" variant="h6" >
									{tme} horas
								</Typography>
								<Timer />
							</div>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="p" variant="p"  paragraph style={{fontSize:15}}>
								TMA
							</Typography>
							<div style={{display:'flex', color:'#4d4d4d',alignItems:'center',flexDirection:'row', justifyContent:'space-between'}}>
								<Typography component="p" variant="h6" >
									{tma} horas
								</Typography>
								<Timer />
							</div>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="p" variant="p"  paragraph style={{fontSize:15}}>
								Total de tickets
							</Typography>
							<div style={{display:'flex', color:'#4d4d4d',alignItems:'center',flexDirection:'row', justifyContent:'space-between'}}>
								<Typography component="p" variant="h6" >
									{GetTickets("open", "true", "false") + GetTickets("pending", "true", "false") + GetTickets("closed", "true", "false")} tickets
								</Typography>
								<ChatIcon />
							</div>
						</Paper>
					</Grid>

					<Grid item xs={12} style={{marginTop:50}}>
						<Paper className={classes.fixedHeightPaper}>
							<Chart />
						</Paper>
					</Grid>

					<Grid item xs={12} style={{marginTop:50}}>
						<Paper className={classes.fixedHeightPaper} style={{height:'100%',overflow:'auto', maxHeight:500, width:'auto'}}>
							<Title style={{ color:'#4d4d4d'}}>
								Últimos tickets
							</Title>

							<Table size="medium" style={{width:'auto', overflow:'auto'}}>
								<TableHead>
									<TableRow>
										<TableCell padding="checkbox" />
										<TableCell style={{width:'250px !important'}}>{i18n.t("contacts.table.name")}</TableCell>
										<TableCell>Atendente</TableCell>
										<TableCell align="left">
											{i18n.t("contacts.table.whatsapp")}
										</TableCell>
										<TableCell align="left">
											{i18n.t("contacts.table.email")}
										</TableCell>
										<TableCell align="left">
											Negociação
										</TableCell>
										<TableCell align="left">
											N. Ticket
										</TableCell>
										<TableCell align="left">
											Canal
										</TableCell>
										<TableCell align="left">
											Departamento
										</TableCell>
										<TableCell align="left">
											Origem
										</TableCell>
										<TableCell align="left">
											Status
										</TableCell>
										<TableCell align="center">

										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>

									{GetTicketsFull("open", "true", "false").map((t, index) => (

									<TableRow key={t.id} >
										<TableCell style={{ paddingRight: 0 }}>
											{<Avatar src={t.contact.profilePicUrl} />}
										</TableCell>
										<TableCell  style={{width:200}}>{t.contact.name}</TableCell>
										<TableCell>{t.user.name}</TableCell>
										<TableCell align="left">{t.contact.number}</TableCell>
										<TableCell align="left">{t.contact.email}</TableCell>
										<TableCell align="left">R$ {t.value}</TableCell>
										<TableCell align="left">{t.id}</TableCell>
										<TableCell align="left">{t.whatsapp && t.whatsapp.name}</TableCell>
										<TableCell align="left">{t.queue && t.queue.name}</TableCell>
										<TableCell align="left">{t.origem}</TableCell>
										<TableCell align="left">
											<Chip label={t.status === 'closed' ? 'Fechado' : (t.status === 'open' ? 'Em atendimento' : 'Aguardando')} color={t.status === 'closed' ? 'secondary' : (t.status === 'open' ? 'primary' : 'default')} />
										</TableCell>
										<TableCell align="center">
											<Tooltip title={ 'Abrir o ticket'} >
												<OpenInBrowserIcon style={{cursor:'pointer'}} onClick={() => openTicket(t.id)}/>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}

								</TableBody>
							</Table>


						</Paper>
					</Grid>

					<Grid item xs={12} style={{marginTop:50}}>
						<Paper className={classes.fixedHeightPaper}>
							<Chart2 />
						</Paper>
					</Grid>

				</Grid>
			</Container>
		</div>
	)
}

export default Dashboard