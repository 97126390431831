import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import NoteIcon from '@mui/icons-material/Note';
import MicIcon from '@material-ui/icons/Mic';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import openSocket from "../../services/socket-io";

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},

	userTag: {
		position: "absolute",
		marginRight: 5,
		right: 5,
		bottom: 5,
		background: "#2576D2",
		color: "#ffffff",
		border: "1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 10,
		fontSize: "0.9em"
	},
}));

const TicketListItem = ({ ticket, updateCount }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [display, setDisplay] = useState('flex');
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			const res = await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
			updateCount()
			document.getElementById('ticket'+id).style.display = 'none'
			//setDisplay('none')
			history.push('/tickets/'+id)

		} catch (err) {
			setLoading(false);
			//toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}

	};

	const handleSelectTicket = id => {
		ticket.unreadMessages = 0;
		history.push(`/tickets/${id}`);
	};

	return (
		<React.Fragment key={ticket.id} >
			<Tooltip
				arrow
				placement="top"
				title={(ticket.status === "pending" && ticket.lastMessage !== '') ? ticket.lastMessage :''}
			>
				<ListItem
					id={'ticket'+ticket.id}
					style={{display:display}}
					dense
					button
					onClick={e => {
						if (ticket.status === "pending") return;
						handleSelectTicket(ticket.id);
					}}
					selected={ticketId && +ticketId === ticket.id}
					className={clsx(classes.ticket, {
						[classes.pendingTicket]: ticket.status === "pending",
					})}
				>
					<Tooltip
						arrow
						placement="right"
						title={ticket.queue?.name || "Sem departamento"}
					>
					<span
						style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
						className={classes.ticketQueueColor}
					></span>
					</Tooltip>
					<ListItemAvatar>
						<Avatar src={ticket?.contact?.profilePicUrl} />
					</ListItemAvatar>
					<ListItemText
						disableTypography
						primary={
							<span className={classes.contactNameWrapper}>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{ticket.contact.name}


							</Typography>
								{ticket.status === "closed" && (
									<Badge
										className={classes.closedBadge}
										badgeContent={"fechado"}
										color="secondary"
									/>
								)}

								{ticket.lastMessage && (

									<Typography
										className={classes.lastMessageTime}
										component="span"
										variant="body2"
										style={{marginRight: (ticket.unreadMessages > 0 ) ? 11.5 : '' ,color: (ticket.unreadMessages > 0 ) ? '#4CAF4F' : '#6c6c6c'}}
									>
										{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
											<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
										) : (
											<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
										)}
									</Typography>

								)}
								{ticket.whatsappId && (
									<div className={classes.userTag} title={i18n.t("ticketsList.connectionTitle")}>{ticket.whatsapp?.name}</div>
								)}
						</span>
						}
						secondary={
							<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{ticket.lastMessage ? (

									ticket.lastMessage.includes('audioMessage') ? (
										<div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
											<MicIcon/>
											<MarkdownWrapper> Áudio</MarkdownWrapper>
										</div>

									) : ticket.lastMessage.includes('imageMessage') ? (
										<div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
											<PhotoCameraIcon style={{fontSize:20, marginRight:3}}/>
											<MarkdownWrapper> Foto</MarkdownWrapper>
										</div>
									) : ticket.lastMessage.includes('videoMessage') ? (
										<div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
											<MovieCreationIcon style={{fontSize:20, marginRight:3}}/>
											<MarkdownWrapper> Vídeo</MarkdownWrapper>
										</div>
									) : ticket.lastMessage.includes('stickerMessage') ? (
										<div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
											<NoteIcon style={{fontSize:20, marginRight:3}}/>
											<MarkdownWrapper> Figurinha</MarkdownWrapper>
										</div>
									) : ticket.lastMessage.includes('documentMessage') || ticket.lastMessage.includes('documentWithCaptionMessage') ? (
										<div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
											<AssignmentIcon style={{fontSize:20, marginRight:3}}/>
											<MarkdownWrapper> Documento</MarkdownWrapper>
										</div>
									) : (

										<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
									)
								) : (
									<br />
								)}

								{ticket.tagTicket && (
									<>
										<br/>
										<Badge
											style={{backgroundColor: ticket.tagTicket.color, color: '#000',
												fontSize:11, borderRadius:10, padding:'0 5px', width:'auto'
											}}
										>
											<span style={{padding:0,margin:0}}>{ticket.tagTicket.name}</span>
										</Badge>
									</>
								)}
							</Typography>

							<Badge
								style={{position:'absolute', top:19,right:3.9, borderColor:'#fff', borderWidth:5}}
								className={classes.newMessagesCount}
								badgeContent={ticket.unreadMessages}
								classes={{
									badge: classes.badgeStyle,
								}}
							/>
						</span>
						}
					/>
					{ticket.status === "pending" &&  (
						<ButtonWithSpinner
							color="primary"
							variant="contained"
							className={classes.acceptButton}
							size="small"
							loading={loading}
							onClick={e => handleAcepptTicket(ticket.id)}
						>
							{i18n.t("ticketsList.buttons.accept")}
						</ButtonWithSpinner>
					)}

				</ListItem>
			</Tooltip>
			<Divider variant="inset" component="li" />
		</React.Fragment>
	);
};

export default TicketListItem;
